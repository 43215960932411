import React, {Component} from 'react'
import {Link} from 'gatsby'
import Seo from '../components/SEO'
import CartItemList from '../components/CartItemList'
import CartSummary from '../components/CartSummary'
import CartContext from '../components/Context/CartContext'
import Layout from '../components/Layout'
import {Button, Container, Header, Message } from 'semantic-ui-react'
import * as styles from './styles/pages.module.css'

const Helper = require('../helper')
const Negosyo = require('../../lib/negosyo')

class Cart extends Component {
	state = {
		loading:true,
		items:[],
		completed:false,
		orderCode:null,
		orderRefStr:"",
		orderType:undefined,
		meta:{},
		cartId:{},
		username: "",
		loggedIn:null,
		loadingApi:null,
		allAvailable:true, //all items are available
		openEditList:[], //list of items that have their edit quantity form opened
		errorCheckout:null,
		errorGetItems:false,
		timeOutRef:null,
		deliveryLocationCode:"",
		deliveryName:"",
		deliveryType:"",
		deliveryFee:0,
		deliveryDiscount:0,
		deliveryCutOff:0,
		deliveryTotal:0,
		completestatus: "",
		processorname: "",
		processorcontact: ""
	}
	static contextType = CartContext

	handleAddOpenList = (product_id) => {
		const temp =[{
			product_id,
		}]


		this.setState({
			openEditList:this.state.openEditList.concat(temp)
		})
	}

	handleRemoveOpenList = (product_id) => {
		const temp2= this.state.openEditList.filter( (list)=>{
			return list.product_id!==product_id
		})
		this.setState({
			openEditList:temp2
		})
	}

	getCartItems = () => {
		const cartIdLocal = localStorage.getItem('mcart')
		if(cartIdLocal) {
			if (this.state.username.length > 0) {
				// TODO Better solution
				(async () => {
					const response = await Negosyo.getCartUserItems(cartIdLocal, this.state.username);
					this.updateCartItems(cartIdLocal, response)
				})();
			} else {
				Negosyo.getCartItems(cartIdLocal)
				.then((response) => {
					this.updateCartItems(cartIdLocal, response);
				}).catch((err)=>{
					this.setState({
						errorGetItems:true
					})
				})
			}
		}else {
			let {gettingCartId} = this.context
			if( gettingCartId===false && localStorage.getItem('mcart')===null ) {
				this.setState({
					errorGetItems:true
				})
			}else {
				const timeOut = setTimeout(()=>{
					this.getCartItems()
				}, 250)
				this.setState({
					timeOutRef:timeOut
				})
			}
		}
	}

	updateCartItems = (cartIdLocal, response) => {
		//problem with api like giving out empty array or wrong object
		if(response.data===undefined || response.meta===undefined) {
			throw new Error("api error")
		}

		this.setState({
			items:response.data,
			meta:response.meta,
			cartId:cartIdLocal,
			loading:false,
		}, ()=>{
			this.updateDeliveryTotal()
			this.handleCheckoutStep(1, 'Review')
		});
	}

	componentDidMount() {
		Negosyo.isLoggedIn()
		.then((isLoggedIn)=>{
			if(isLoggedIn) {
				this.handleLoginChange();
			} else {
				this.getCartItems()
			}
		})
	}

	handleLoginChange = () => {
		Negosyo.getCurrentUserDetails()
		.then((user)=>{
			this.setState({
				username: user.username,
				loggedIn: true
			}, function() {
				this.getCartItems()
			})
		}).catch((err)=>{

		})
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOutRef)
	}


	setAvailableFalse = () =>{
		if(this.state.allAvailable) {
			this.setState({
				allAvailable:false
			})
		}
	}

	handleCheckoutStep = ( step, option) => {
		window.dataLayer = window.dataLayer || [];

		let totalvalue = 0;
		let productlist = [];
		for (let removeidx = 0; removeidx < this.state.items.length; removeidx++)
		{
			for (let removedataidx = 0; removedataidx < this.state.items[removeidx].data.length; removedataidx++)
			{
				productlist.push({
					name: this.state.items[removeidx].data[removedataidx].name,
					quantity: this.state.items[removeidx].data[removedataidx].quantity,
					price: this.state.items[removeidx].data[removedataidx].unit_price.amount,
					id: this.state.items[removeidx].data[removedataidx].sku,
					'item_id': this.state.items[removeidx].data[removedataidx].sku,
					'item_name': this.state.items[removeidx].data[removedataidx].name,
					'currency': "PHP",
				});
				totalvalue = totalvalue + (parseFloat(this.state.items[removeidx].data[removedataidx].unit_price.amount) * parseInt(this.state.items[removeidx].data[removedataidx].quantity, 10));
			}
		}
		window.dataLayer.push({
			'event': 'checkout',
			'ecommerce': {
				'checkout': {
					'actionField': {'value': totalvalue, 'step': step, 'option': option},
					'products': productlist
				}
			}
		});
	}


	retryGetCart = () => {

		const cartIdLocal = localStorage.getItem('mcart')
		this.setState({
			errorGetItems:false
		})
		if(cartIdLocal===null) {
			//no cart Id so initiate retry of gettingCartId in cartProvider context then do this.getCartItems
			let {initiateRetry, gettingCartId} = this.context
			if(gettingCartId===false) {
				initiateRetry()
				.then(()=>{

					this.getCartItems()
				})
			}else {
				// cart Provider is already somehow getting a new cartId
				this.getCartItems()
			}

		}else {
			//has a cartId but failed getting the cart items
			this.getCartItems()
		}

	}

	handleCheckout = async (data) => {
		const cartId = await localStorage.getItem('mcart')
		this.setState({
			loadingApi:data.customerDetail
		})

		//const customerId = localStorage.getItem('mcustomer')
		let submitData = data
		//let name
		//let email
		//let contact
		let {
			billingAddress,
			shippingAddress,
			customerDetail
		} = submitData
		if(billingAddress) {
			//name = billingAddress.name
			//email = billingAddress.email
			//contact = billingAddress.contact
			if(shippingAddress===undefined) {
				submitData.shippingAddress = billingAddress;
				shippingAddress = billingAddress;
			}
		}


		/*turn address to components*/
		if(billingAddress) {
			if(billingAddress.unitNumber===undefined ) {
				billingAddress.unitNumber = "";
			}
			if(billingAddress.buildingName===undefined ) {
				billingAddress.buildingName = "";
			}
			Helper.handleFloor(billingAddress)
			const components = Helper.handleComponentCreation(billingAddress)

			submitData.billingAddress = components;
		}

		if(shippingAddress) {
			if(shippingAddress.unitNumber===undefined ) {
				shippingAddress.unitNumber = "";
			}
			if(shippingAddress.buildingName===undefined) {
				shippingAddress.buildingName = ""
			}
			Helper.handleFloor(shippingAddress)
			const components2 = Helper.handleComponentCreation(shippingAddress)
			submitData.shippingAddress = components2;
		}

		const customer = customerDetail //|| {name, contact}
		/*
		if(email ) {
			customer.email = email
		}
		*/
		submitData.customer = customer;
		if( submitData.customerDetail ) {
			delete submitData.customerDetail //delete it since its redundant with customer
		} // this seems unnecessary , review this if ever, I should just rename customerDetail to customer

		if(submitData.pickupCode) {
			if(submitData.pickupCode==="NNBUSSHIPPING") {
				this.setState({
					orderType:"bus shipping"
				})
			}else {
				this.setState({
					orderType:"pick up"
				})
			}

		}else if(submitData.billingAddress) {
			this.setState({
				orderType:"delivery"
			})
		}
		if(localStorage.getItem("referral") ) {
			submitData.referral = localStorage.getItem("referral")

		}
		/*
		//console.log(submitData)
		this.setState({
			loadingApi:null,
			completed:true
		})
		window.scrollTo(0,0)
		return false
		*/


		try {
			const response = await Negosyo.checkoutCart(cartId, submitData)
			if(response.status==="OK") {
				localStorage.removeItem("referral")
				let productlist = [];
				var revenue = 0;  // For FB Data
				window.dataLayer = window.dataLayer || [];
				for (let removeidx = 0; removeidx < this.state.items.length; removeidx++)
				{
					for (let removedataidx = 0; removedataidx < this.state.items[removeidx].data.length; removedataidx++)
					{
						productlist.push({
							name: this.state.items[removeidx].data[removedataidx].name,
							quantity: this.state.items[removeidx].data[removedataidx].quantity,
							price: this.state.items[removeidx].data[removedataidx].unit_price.amount,
							id: this.state.items[removeidx].data[removedataidx].sku,
							'item_id': this.state.items[removeidx].data[removedataidx].sku,
							'item_name': this.state.items[removeidx].data[removedataidx].name,
							'currency': "PHP",
						});
						revenue = revenue + (this.state.items[removeidx].data[removedataidx].unit_price.amount * this.state.items[removeidx].data[removedataidx].quantity);
					}
				}
				window.dataLayer.push({
						'event': 'purchase',
						'ecommerce': {
							'purchase': {
								'actionField': {
									'id':response.ordercode,
									'revenue': revenue
								},
								'products': productlist
							}
						}
					});


				let { resetProvider } = this.context
				resetProvider()
				//resets the cart provider, resets most of the state of cart Provider,
				//deletes localStorage pertaining to cart and tries to get a new cartId

				let processorname = "";
				let processorcontact = "";
				let completestatus = "DRAFT";
				if (response.hasOwnProperty("orderstatus")) {
					completestatus = response.orderstatus;
					if (response.orderstatus === "SUBMITTED") {
						processorname = response.processorname;
						processorcontact = response.processorcontact;
					}
				}

				this.setState({
					completestatus: completestatus,
					processorname: processorname,
					processorcontact: processorcontact
				}, function() {
					this.setState({
						loadingApi:null,
						completed:true,
						orderCode:response.ordercode,
						orderRefStr: response.hasOwnProperty("orderref")?response.orderref:"Order # is "+response.ordercode
					});
					window.scrollTo(0,0);
				});

				return true
			}else if(response.status==="Error") {
				this.setState({
					errorCheckout:response.message,
					loadingApi:null
				})


				return false
			}else {
				this.setState({
					errorCheckout:"Unable to finish checkout.",
					loadingApi:null
				})


				return false
			}

		} catch (e) {
			this.setState({
				errorCheckout:"Unable to finish checkout.",
				loadingApi:null
			})
			return false
		}

	}

	//maybe take out oldQty as its not needed anymore after switching api
	handleChangeQty = async (product_id, cartItemId, oldQty, newQty ) => {
		this.setState({
			loadingApi:product_id
		})
		const {
			cartId,
			username
		} = this.state

		if (username.length > 0) {
			const response = await Negosyo.editUserCartItemQuantity(username, cartItemId, newQty, cartId);
			if (response.hasOwnProperty("data")) {
				this.cartQTYChanged(cartId, product_id, oldQty, newQty, response.data, response.meta);
			} else {
				this.setState({
					loadingApi:null
				});
			}
		} else {
			Negosyo.editCartItemQuantity(cartItemId, newQty, cartId)
			.then(({data, meta})=>{
				this.cartQTYChanged(cartId, product_id, oldQty, newQty, data, meta);
			}).catch(()=>{
				this.setState({
					loadingApi:null
				})
			})
		}


	}
	cartQTYChanged = (cartId, product_id, oldQty, newQty, data, meta) => {
		if(data===undefined || meta===undefined) {
			throw new Error("api return error") //just in case the api didn't return proper values
		}
		let total = 0
		let temp = {}
		for(let i = 0; i<data.length; i++) {
			total += data[i].data.reduce((a, c) => a + c.quantity, 0)
			if(temp.name===undefined) {
				for(let o = 0;o< data[i].data.length;o++) {
					if(data[i].data[o].product_id===product_id) {
						temp.id=data[i].data[o].sku
						temp.name=data[i].data[o].name
						temp.price=data[i].data[o].unit_price.amount
						break;
					}
				}
			}
		}
		window.dataLayer = window.dataLayer || [];

		if(oldQty > newQty ) {
			window.dataLayer.push({
				'event': 'removeFromCart',
				'ecommerce': {
					'remove': {
						'value': parseInt(oldQty-newQty, 10) * parseFloat(temp.price),
						'products': [
							{
								'name': temp.name,
								'quantity': oldQty-newQty,
								'price': parseFloat(temp.price),
								'id': temp.id,
								'item_id': temp.id,
								'item_name': temp.name,
								'currency': "PHP",

							}
						]
					}
				}
			});
		}else {
			window.dataLayer.push({
				'event': 'addToCart',
				'ecommerce': {
					'add': {
						'value': parseInt(newQty-oldQty, 10) * parseFloat(temp.price),
						'products': [
							{
								'name': temp.name,
								'price': parseFloat(temp.price),
								'id': temp.id,
								'quantity':newQty-oldQty,
								'item_id': temp.id,
								'item_name': temp.name,
								'currency': "PHP",
							}
						]
					}
				}
			});
		}
		let {updateCartCount} = this.context
		updateCartCount(total, cartId)
		this.setState({
			items:data,
			meta:meta,
			allAvailable:true,
			loadingApi:null
		}, this.updateDeliveryTotal)

		this.handleRemoveOpenList(product_id)
	}

	handleDeliveryChange = (type, label, amount, discount, cutoff, locationcode) => {
		this.setState({
			deliveryLocationCode: locationcode,
			deliveryType: type,
			deliveryCutOff: cutoff,
			deliveryDiscount: discount,
			deliveryFee: amount,
			deliveryName: label
		}, this.updateDeliveryTotal);
	}

	updateDeliveryTotal = () => {
		var tmptotal = 0;
		var i = 0;
		var hasonprem = false;

		i = 0;
		while (i < this.state.items.length) {
			if ("onprem-concepts" === this.state.items[i].meta.organizationId) {
				hasonprem = true;
				break;
			}
			i = i + 1;
		}

		i = 0;
		while (i < this.state.items.length) {
			if ("onprem-concepts" === this.state.items[i].meta.organizationId || hasonprem === false) {
				if (this.state.items[i].meta.display_price.with_tax.amount > this.state.deliveryCutOff) {
					tmptotal = tmptotal + this.state.deliveryFee - this.state.deliveryDiscount;
				} else {
					tmptotal = tmptotal + this.state.deliveryFee;
				}
			}
			i = i + 1;
		}

		this.setState({
			deliveryTotal: tmptotal
		});
	}

	handleRemoveFromCart = itemId => {
		window.dataLayer = window.dataLayer || [];
		for (let removeidx = 0; removeidx < this.state.items.length; removeidx++)
		{
			for (let removedataidx = 0; removedataidx < this.state.items[removeidx].data.length; removedataidx++)
			{
				if (this.state.items[removeidx].data[removedataidx].id === itemId) {
					window.dataLayer.push({
							'event': 'removeFromCart',
							'ecommerce': {
								'remove': {
									'value': parseInt(this.state.items[removeidx].data[removedataidx].quantity, 10) * parseFloat(this.state.items[removeidx].data[removedataidx].unit_price.amount),
									'products': [
										{
											'name': this.state.items[removeidx].data[removedataidx].name,
											'quantity': parseInt(this.state.items[removeidx].data[removedataidx].quantity, 10),
											'price': parseFloat(this.state.items[removeidx].data[removedataidx].unit_price.amount),
											'id': this.state.items[removeidx].data[removedataidx].sku,
											'item_id': this.state.items[removeidx].data[removedataidx].sku,
											'item_name': this.state.items[removeidx].data[removedataidx].name,
											'currency': "PHP",
										}
									]
								}
							}
						});
					removeidx = this.state.items.length;
					break;
				}
			}
		}

		this.setState({
			loadingApi:itemId
		})
		const {
			cartId,
			username
		} = this.state
		if (username.length > 0) {
			(async () => {
				const response = await Negosyo.removeFromUserCart(username, itemId, cartId);
				if (response.hasOwnProperty("data")) {
					this.cartItemRemoved(cartId, response.data, response.meta)
				} else {
					this.setState({
						loadingApi:null
					})
				}
			})();

		} else {
			Negosyo.removeFromCart(itemId, cartId)
			.then(({data, meta}) => {
				this.cartItemRemoved(cartId, data,meta);
			}).catch((err)=>{
				this.setState({
					loadingApi:null
				})
			})
		}
	}

	cartItemRemoved = (cartId, data, meta) => {
		if(data===undefined || meta===undefined) {
			throw new Error("api return error") //just in case the api didn't return proper values
		}
		let total = 0
		for(let i = 0; i<data.length; i++) {
			total += data[i].data.reduce((a, c) => a + c.quantity, 0)
		}
		let {updateCartCount} = this.context
		updateCartCount(total, cartId)
		this.setState({
			items:data,
			meta:meta,
			allAvailable:true,
			loadingApi:null
		})

	}

	resetErrorCheckout = ()=> {
		this.setState({
			errorCheckout:null
		})
	}

	render() {

		const {
			completed,
			orderCode,
			orderRefStr,
			items,
			loading,
			cartId,
			orderType,
			loadingApi,
			loggedIn,
			errorGetItems,
			openEditList,
			meta,
			allAvailable,
			errorCheckout,
			deliveryType,
			deliveryCutOff,
			deliveryDiscount,
			deliveryFee,
			deliveryName,
			deliveryTotal,
			completestatus, processorname, processorcontact
		} = this.state
		const {
			handleDeliveryChange,
			handleRemoveFromCart,
			handleChangeQty,
			setAvailableFalse,
			handleAddOpenList,
			handleRemoveOpenList,
			handleCheckout,
			retryGetCart,
			handleCheckoutStep,
			resetErrorCheckout
		} = this
		const rest = {
			completed, items, loading, cartId, orderType, loadingApi, loggedIn,
			deliveryType, deliveryCutOff, deliveryDiscount, deliveryFee, deliveryName,
			completestatus, processorname, processorcontact
		}
		const {
			location
		} = this.props

		const showorgleveldeliveryfees = true;

		return (
			<Layout location={location} loggedIn={loggedIn}>
				<Seo title="Cart" meta={[{name: `robots`, content: `noindex`}]} />
				<Container className={styles.container}>

					{!completed && (
						<>
							<Header className={styles.superHeader}>
								Your Cart
								{(loggedIn && items.length>0)&&<div style={{textAlign:"center", fontSize:"0.6em", fontWeight:"normal"}}>
									<Link to='/my-account/orders'>(Order History)</Link>
								</div>}
							</Header>
						</>
					)}
					{errorGetItems===false ? (
						<React.Fragment>
							<CartItemList
								{...rest}
								removeFromCart={handleRemoveFromCart}
								handleChangeQty={handleChangeQty}
								handleUnavailable={setAvailableFalse}
								handleAddOpenList={handleAddOpenList}
								handleRemoveOpenList={handleRemoveOpenList}
								openList={openEditList}
								orderCode={orderCode}
								orderRefStr={orderRefStr}
								showorgleveldeliveryfees={showorgleveldeliveryfees}
							/>
							{!loading && !completed && (
								<CartSummary
									{...meta}
									deliveryTotal={deliveryTotal}
									handleDeliveryChange={handleDeliveryChange}
									handleCheckout={handleCheckout}
									items={items}
									changeLoggedIn={()=>{
										this.handleLoginChange();
									}}
									handleCheckoutStep={handleCheckoutStep}
									disableCheckout={loadingApi}
									productAvailability={allAvailable}
									openList={openEditList}
									errorCheckout={errorCheckout}
									resetErrorCheckout={resetErrorCheckout}
									showorgleveldeliveryfees={showorgleveldeliveryfees}
								/>
							)}
						</React.Fragment>
					):(
						<React.Fragment>
							<Message className={`${styles.msg} ${styles.centerText}`} negative>
								<Message.Header >
									Unable to retrieve your cart
								</Message.Header>
							</Message>
							<div className={`${styles.text} ${styles.centerText}`}>

								<Button
									icon="redo"
									size="big"
									className={styles.iconButton}
									onClick={retryGetCart}
								/>
								<br/><br/>
								Try Again
							</div>
						</React.Fragment>
					)}

				</Container>
			</Layout>
		)
	}
}

export default Cart
