import React from 'react'
import {Link} from 'gatsby'
import {
	Loader,
	Message,
	Segment,
	Header,
	Divider
} from 'semantic-ui-react'
import * as styles from './styles/cartItemList.module.css'
import ItemGroup from './cartItemListGrouped'

const formatTools = require("../../../lib/formatTools");


const CartItemListComponent = ({
	items,
	loggedIn,
	removeFromCart,
	loading, completed,
	orderType,
	qtyList,
	handleChangeQty,
	loadingApi,
	handleUnavailable,
	handleAddOpenList,
	handleRemoveOpenList,
	openList,
	orderCode, orderRefStr,
	deliveryType, deliveryCutOff, deliveryDiscount, deliveryFee, deliveryName,
	completestatus, processorname, processorcontact,
	showorgleveldeliveryfees
}) => {
	if (loading) {
		return (
			<Loader active inline="centered" />
		)
	}

	if (completed) {
		const ordernumtext = orderRefStr.length>0?orderRefStr:orderCode;
		const numorders = orderRefStr.length>0?orderRefStr.replace(" and ", ",").split(",").length:1;
		return (
			<Message success className={styles.message} name={"checkoutSuccessMsg"}>
				<Message.Header>{"Thank you!  Your Order "+(numorders>1?"#s are ":"# is ")+ordernumtext+"."}</Message.Header>
				<br/>
				{completestatus === "SUBMITTED" ?
					<React.Fragment>
						{numorders>1?"They have":"This has"} been forwarded to {processorname}.
						{processorcontact !== "" && <>
							<br/>
							You may contact them directly at {processorcontact}.
						</>}
					</React.Fragment>
				:
					<React.Fragment>
						Our team should get in touch within the next business day to update you of its status.
					</React.Fragment>
				}
				<br/>
				Please use the Order #{(numorders>1?"s "+ordernumtext:" "+ordernumtext)} to keep track of your transaction{(numorders>1?"s":"")}.<br/>
				<br/>
				{orderType==="delivery" &&(
					<React.Fragment>
						The final amount and the delivery schedule will be based on the quantities we can serve.<br/>
						Standard delivery time is from 8am to 6pm<br/>
						<br/>
						<Message.Header>Reminders: </Message.Header>
						Please prepare the exact amount to receive the items.<br/>
						Our delivery team will not bring change, and will be unable to release goods unless the exact amount is provided.<br/>

					</React.Fragment>
				)}
				{orderType==="pick up" && (
					<React.Fragment>
						The final amount will be based on the quantities we can serve.<br/>
						<br/>
						<Message.Header>Reminders: </Message.Header>
						Please note that payment will be in store, upon pick up of items.<br/>
					</React.Fragment>
				)}
				{orderType==="bus shipping" && (
					<React.Fragment>
						<br/>
						Please allow us some time to verify if we can ship to the terminal.<br/>
						Our representative will explain the procedure and requirements to proceed.<br/>
					</React.Fragment>
				)}
				{completestatus === "DRAFT" && (
					<React.Fragment>
						<br/>
						For the meantime, a text message with an authorization code will be sent to your number.<br/>
						<Link to={`/confirm/`}>Please click here to confirm your order when you receive the message.</Link><br/>
						You may also confirm your order anytime by clicking on the corresponding link at the bottom of our site.<br/>
					</React.Fragment>
				)}
				<br/>
				<Link to={`/`}>Shop for more items</Link><br/>
				{loggedIn&&<>
					<p>
						or
					</p>
					Go to <Link to='/my-account/orders'> Order History </Link>
				</>}
			</Message>
		)
	}
	if(items===undefined) {
		return (
			<Message negative className={styles.message}>
				<Message.Header>Unable to get your Cart</Message.Header>

			</Message>
		)
	}
	if(items.length === 0) {
		return (
			<Message warning className={styles.message}>
				<Message.Header>Your cart is empty</Message.Header>
				<p>
					Please add items you'd like to purchase.
				</p>
				<Link to='/'> Shop for Items </Link>
				{loggedIn&&<>
					<p>
						or
					</p>
					Go to <Link to='/my-account/orders'> Order History </Link>
				</>}
			</Message>
		)
	}

	var hasonprem = false;
	var organizationidx = 0;
	while (organizationidx < items.length) {
		if ("onprem-concepts" === items[organizationidx].meta.organizationId) {
			hasonprem = true;
			break;
		}
		organizationidx++;
	}
	//new data structure for cart items
	return (
		<React.Fragment>
			{items.map(({data, meta}, index)=>{

				var discountReached = meta.display_price.with_tax.amount > deliveryCutOff;
				const deliveryFeeFound = deliveryFee >= 0;
				const feename = (deliveryType === "bus"?"Processing Fees": "Delivery Fees")

				var deliverystr = "Php" + formatTools.currency(deliveryFee);
				var deliveryDiscountFinalAmount = 0;
				var deliveryDiscountCondName = deliveryName;
				var deliveryDiscountCond = "Php" + formatTools.currency(deliveryCutOff - meta.display_price.with_tax.amount);
				var deliveryDiscountCondSuffix= " or more";

				var showonprempromo = false;

				if ("onprem-concepts" !== meta.organizationId && showorgleveldeliveryfees) {
					if (hasonprem === false) {
						// 2023-09-25 set to false to centralize Delivery Fees for now to prevent confusion
						showonprempromo = true;
						if (discountReached) {
							deliveryDiscountFinalAmount = deliveryDiscount;
						}
					} else {
						deliveryDiscountFinalAmount = deliveryFee;
						discountReached = true;
					}
				} else {
					if (discountReached) {
						deliveryDiscountFinalAmount = deliveryDiscount;
					}
				}
				if (deliveryType === "pick up") {
					deliverystr = "";
				} else if(deliveryType === "bus" || (deliveryType === "delivery" && deliveryFeeFound===false)) {
					deliverystr = "For Assessment";
				}

				const finaldeliveryamount = deliveryFee - deliveryDiscountFinalAmount;
				const price = (showorgleveldeliveryfees?"Php"+ formatTools.currency(meta.display_price.with_tax.amount+finaldeliveryamount): "Php"+ formatTools.currency(meta.display_price.with_tax.amount));
				var deliverydiscountstr = "(Php" + formatTools.currency(deliveryDiscountFinalAmount)+")";
				return (
					<React.Fragment key={meta.organizationId}>
						<Segment className={styles.organizationGroup}>
							<div className={styles.organizationHeaderDiv}>
								<Header className={`${styles.header} ${styles.groupHeader}`}>
									<Link to={`/organization/${meta.organizationUrl}/`}>
										{meta.organizationName}
									</Link>
								</Header>
							</div>
							<Divider/>
							<ItemGroup
								mode={"cart"}
								removeFromCart={removeFromCart}
								items={data}
								loggedIn={loggedIn}
								handleChangeQty={handleChangeQty}
								loadingApi={loadingApi}
								handleUnavailable={handleUnavailable}
								handleAddOpenList={handleAddOpenList}
								handleRemoveOpenList={handleRemoveOpenList}
								openList={openList}
							/>
							<Divider/>
							<div className={styles.organizationHeaderDiv}>
								<div className={styles.text + " " + styles.organizationSubtotal}>
									{(showorgleveldeliveryfees && (deliveryFee > 0 || deliveryDiscountFinalAmount > 0 || deliverystr.length > 0)) && <>
										{deliverystr!=="" && <>
											<div className={""}>{meta.organizationName} {feename}: {deliverystr}</div>
											<br/>
										</>}
										{deliveryFeeFound && !discountReached && (
											<Message className={styles.deliveryDiscountMessage} compact>
												<div className={styles.italic}>{deliveryDiscountCondName} when<br/> you add <b>{deliveryDiscountCond}</b>{deliveryDiscountCondSuffix}</div>
											</Message>
										)}
										{deliveryDiscountFinalAmount>0 && <>
											<div className={""}>
												Delivery Discounts: {deliverydiscountstr}
											</div>
											<br/>
										</>}
										{showonprempromo && <>
											&nbsp;&nbsp;
											<Message className={styles.deliveryDiscountMessage} compact>
												<div className={styles.italic}>
													<b>[Limited Offer]</b> Waived {feename} when<br/> you add
														<Link to={`/organization/onprem-concepts/`}>
															&nbsp;Onprem Concepts&nbsp;
														</Link>
													item(s)</div>
											</Message>
										</>}
									</>}
									<div className={""}>Subtotal: {price}</div>
									{(showorgleveldeliveryfees === false && (deliveryFee > 0 || deliveryDiscountFinalAmount|| deliverystr.length > 0) ) > 0 &&
										<Message className={styles.deliveryDiscountMessage} compact>
											<div className={styles.italic}>Subject to {feename}</div>
										</Message>
									}
								</div>
							</div>
						</Segment>
					</React.Fragment>
				)
			})}
		</React.Fragment>
	)



}

export default CartItemListComponent

