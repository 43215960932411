import React, { Component } from 'react'
import {Link} from 'gatsby'
import {
	Button,
	Checkbox,
	Divider,
	Form,
	Grid,
	Header,
	//Icon,
	Label,
	Loader,
	Menu,
	Message,
	Segment
} from 'semantic-ui-react'
import AddressForm from '../Forms/addressFormMod'
import PickUpForm from './Forms/pickUpForm'
import PickUpMethodForm from './Forms/pickUpMethodForm'
import BusShippingForm from './Forms/busShippingForm'
import ShowNewAddress from '../Address/showNewAddress'
import ShowAddress from '../Address/showAddressCart'
import SignInForm from  '../Forms/signInForm'
import SignUpForm from '../Forms/SignUpForms/signUpForm'
//import AuthForm from '../Forms/SignUpForms/authSignUpForm'
import * as styles from './styles/cartSummary.module.css'
import {
	confirmationColor,
	backColor,
	secondaryButtonColor,
	//activateAccountColor,
	redirectGuestCheckoutColor
} from '../../globalVar'

//const Helper = require('../../helper') can be used to save address when user logs in /sign up in the last checkout step so will leave this
const Negosyo = require('../../../lib/negosyo')


class CartSummary extends Component {
	state = {
		loggedIn:undefined,
		user:undefined,
		shippingAddress:undefined,
		billingAddress:undefined,
		loadingCheckout:false, //loading for checkout/register process
		activeItem:"Method", //for changing sections, maybe this would be better if its name is activeMenuItem or such
		diffAddress:false, //to show if the user wants to use a different address for their billing address
		//signInUp:false, //for process checkout to check if user wants to sign in or sign up, update: sept 22, 2020 might not be used again because of how clunky it was that or the render part for this needs to change
		pickup:undefined, //pickup or delivery or bus shipping
		pickupOptions:undefined, //PickUp options shown to users from the address pickuppoints api
		pickupDetail:undefined, // pickup option user picked for when user picked pick up from pickup or delivery section
		pickupCode:undefined,
		pickupMethod:undefined,  //personally or courier(3rd party e.g. Grab, Lalamove)
		pickupMethodDetail:undefined, //Personally if user picked personally or courier name(e.g. Grab, Lala Move, )
		loadingPickup:false, //for loading the pickup options via fetch, maybe change this so the default value is true so we don't need the setstate for this on componentDidMount
		pickupAddress:undefined, // address from pickup
		pickupGps:undefined, //gps location of the chosen pickup location
		guestCheckOutStage:"sign in",
		name:"",	//for process checkout
		contact:"",  //for process checkout
		email:"", // for process checkout
		loadingProfile:false, //name
		loadingContact:false, //mobile and email
		shippingAddressId:undefined, //used to remember the choice of signed in users for shipping address
		billingAddressId:undefined, //used to remember the choice of signed in users for billing address
		deliveryLocationCode:null,
		deliveryFee:null,
		deliveryCutOff:null,
		deliveryDiscount:null,
		deliveryDiscountLabel:null,
		loadingDeliveryFee:false,
		deliveryFeeFound:null,
		errorDeliveryFee:null,
		showCheckoutDetails:false,
		loadingContactInfo:false, //loading for contact Info stage when user is submitting the contact details for the order
		errorContactInfo:false,
		errorCheckout:'', //for error message while doing checkout like using a contact assigned to a password user for checkout
		timeOut1:undefined,
		stationName:undefined, //for bus shipping station name
		busDetails:undefined,  //for bus shipping Bus Liner (e.g. Victory Liner, Eagle Star etc. )
		checkoutStage:0, //for guest checkout verifying their number or email
		guestAuthCode:"", //auth code for guest checkout
		agreeTerms:false, //to track if players clicked the terms and agreement ( bus shipping only for now )
		menuMessage:undefined, //for showing a message to pick a choice on the right if user keeps clicking the already active menu section
		profile: { //for logged in users for the initial values of address Form when they want to use a new address
			name:undefined
		},
		message: '', // to show non error messages like redirecting  players to forgot-password because of the missing aws account bug or such
		errorGuestCheck:null,
		error: {
			name:undefined,
			contact:undefined,
			guestAuthCode:undefined
		},

		//everything below this is for sign up
		signUpStage:0,
		signUpContact:"",
		signUpPassword:"",

		proper:false,	//if false used Activate Account if true used the sign up button
		transition:false //for state.stage transition

	}

	componentDidMount() {
		//get address from users
		Negosyo.isLoggedIn()
		.then((isLoggedIn)=>{
			if(isLoggedIn) {
				//Negosyo get customer address then set that
				this.setState({loggedIn:true})
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					this.setState({
						user:user,
						loadingProfile:true,
						loadingContact:true
					})
					Negosyo.getUserProfile(user.username).then((response)=>{
						if(response.status==="OK") {
							this.setState(prevState => ({
								name:response.data.name,
								loadingProfile:false,
								profile: {
									...prevState.profile, //prevent race conditions with other api in the future that will also set values for profile
									name:response.data.name
								}
							}))
						}else {
							this.setState({
								loadingProfile:false
							})
						}

					}).catch((err)=>{
						this.setState({
							loadingProfile:false
						})
					})

					Negosyo.getContactList(user.username).then((response)=> {
						if(response.status==="OK") {
							var responseidx = 0;
							var primaryemail = "";
							var primarymobile = "";
							while (responseidx < response.data.length) {
								if( response.data[responseidx].contact.indexOf("@") >= 0 ) {
									if (primaryemail.length < 1 || response.data[responseidx].contact.verified === true) {
										primaryemail = response.data[responseidx].contact;
									}
								}else {
									//mobile number
									if (primarymobile.length < 1 || response.data[responseidx].contact.verified === true) {
										primarymobile = response.data[responseidx].contact;
									}
								}
								responseidx = responseidx + 1;
							}
							this.setState({contact:primarymobile});
							this.setState({email:primaryemail});
							this.setState({loadingContact:false})
						}else {
							this.setState({loadingContact:false})
						}
					}).catch((err)=>{
						this.setState({loadingContact:false})
					})

				}).catch((err)=>{

				})
			}
		})
		this.setState({loadingPickup:true})
		Negosyo.getPickUpPoints()
		.then((addresses)=>{
			this.setState({pickupOptions:addresses, loadingPickup:false})
		}).catch((err)=>{
			this.setState({loadingPickup:false})
		})
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1)
	}

	handleUserLogIn = () => {
		Negosyo.isLoggedIn()
		.then((isLoggedIn)=>{
			if(isLoggedIn) {
				//Negosyo get customer address then set that
				this.setState({
					loggedIn:true
				},()=>{
					this.scrollToTopSection()
				})
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					this.setState({
						user:user,
						loadingContact:true,
						loadingProfile:true
					})
					Negosyo.getUserProfile(user.username).then((response)=>{
						if(response.status==="OK") {
							this.setState(prevState => ({
								name:response.data.name,
								loadingProfile:false,
								profile: {
									...prevState.profile, //prevent race conditions with other api in the future that will also set values for profile
									name:response.data.name
								}
							}))
						}else {
							this.setState({
								loadingProfile:false
							})
						}

					}).catch((err)=>{
						this.setState({
							loadingProfile:false
						})
					})

					Negosyo.getContactList(user.username).then((response)=> {
						if(response.status==="OK") {
							var responseidx = 0;
							var primaryemail = "";
							var primarymobile = "";
							while (responseidx < response.data.length) {
								if( response.data[responseidx].contact.indexOf("@") >= 0 ) {
									if (primaryemail.length < 1 || response.data[responseidx].contact.verified === true) {
										primaryemail = response.data[responseidx].contact;
									}
								}else {
									//mobile number
									if (primarymobile.length < 1 || response.data[responseidx].contact.verified === true) {
										primarymobile = response.data[responseidx].contact;
									}
								}
								responseidx = responseidx + 1;
							}
							this.setState({contact:primarymobile});
							this.setState({email:primaryemail});
							this.setState({
								loadingContact:false
							})
						}else {
							this.setState({
								loadingContact:false
							})
						}
					}).catch((err)=>{
						this.setState({
							loadingContact:false
						})
					})

				}).catch((err)=>{

				})
			}
		})
		this.props.changeLoggedIn()
		this.setState({
			menuMessage:undefined
		})
	}

	toggleStage = ()=> {
		if(this.state.signUpStage) {
			this.setState({
				signUpStage: 0,
				transition:true,
			})
			setTimeout(()=>{
				this.setState({transition:false})
			}, 2000)
		} else {
			this.setState({
				signUpStage: 1,
				transition:true,
				proper:false,
			})
			setTimeout(()=>{
				this.setState({transition:false})
			}, 2000)
		}
	}

	//resendCode for guest checkout
	resendCode = () => {
		let finalContact
		finalContact = this.state.contact //email from process checkout for pickup
		Negosyo.getUser(finalContact)
		.then((data)=>{
			/*this if statement might need || data.status==="Inactive" added*/
			if(data.status==="Active") {
				Negosyo.resendNegosyoCode(finalContact, data.username)
				.then((data)=>{
					if(data.status==="OK") {
						//this.setState({countdown:60})
					}else {
						//shouldn't reach here
					}
				})
				.catch(e =>{

				})
			} else {
				//shouldn't reach here
			}
		}).catch((err)=>{

		})
	}

	handleInputChange = (e, { name, value }) => {
		this.setState({ [name]: value })
		let message
		if(value==='') {
			message = name + " must not be blank"
			this.setState(prevState => ({
				error: {
					...prevState.error,
					[name] : message
				}
			}))
		} else {
			message = null
			this.setState(prevState => ({
				error: {
					...prevState.error,
					[name] : message
				}
			}))
		}
	}

	validateInput = (states) => {
		//quick fix so that the required input gets trimmed so users can't input just spaces for name
		for( let key2 of Object.keys(states) ) {
			this.setState({
				[key2]:this.state[key2].trim()
			})
			states[key2] = states[key2].trim()
		}


		let check = true
		for( let key of Object.keys(states) ) {
			/*
			 *1st)if key is blank and is not an error give an error to the field
			 *2nd)if this isn't a form for billing meaning it shouldn't have an email
			 *then if field is email ignore it everything else check for the 1st condition
			 */
			if( states[key] === '' ) {
				check = false
				this.setState(prevState => ({
					error: {
						...prevState.error,
						[key] : "must not be blank"
					}
				}))

			} else if( key==="contact" ) {
				const mobilePattern = /^(0|\+63)(8|9)([0-9]{9})$/g
				const result = mobilePattern.test(states[key]);
				if(!result) {
					check = false
					this.setState(prevState => ({
						error: {
							...prevState.error,
							[key] : "must be a valid mobile number"
						}
					}))
				}

				//validate if real mobile number
			}
			/*
			else if (
				!( this.isEmail(states[key]) )  &&
				key==="contact"
			) {
				check = false
				this.setState(prevState => ({
					error: {
						...prevState.error,
						[key] : "must be a valid email"
					}
				}))
			}
			*/
		}
		return check
	}

	handleItemClick = (e, {name}) => {
		if(this.state.activeItem==="Checkout") {
			this.setState({
				checkoutStage:0,
				errorCheckout:'',
				message:''
			})
		}
		if(this.state.activeItem==="Contact Information") {
			this.setState({
				errorContactInfo:'',
				message:''
			})
		}
		if(this.state.activeItem===name) {
			this.setState({
				menuMessage:name
			})
		}else {
			this.setState({
				menuMessage:undefined
			})
		}
		this.setState({
			activeItem:name
		},()=>{
			this.scrollToTopSection()
		}) //changing sections
	}

	scrollToTopSection = () =>{
		document.getElementById('section-top').scrollIntoView({
			behavior: 'smooth'
		});
	}

	scrollToCheckoutDetails = () =>{
		const {
			showCheckoutDetails
		} = this.state
		if(showCheckoutDetails) {
			//if the checkout details are showing scroll to the delivery fee
			let index = 0; //desktop
			if(window.innerWidth<=767) {
				index=1; //mobile
			}
			document.getElementsByName('checkout-details')[index].scrollIntoView({
				behavior: 'smooth'
			});
		}else {
			//if the checkout details are not showing scroll to the top of the section
			document.getElementById('section-top').scrollIntoView({
				behavior: 'smooth'
			});
		}

	}

	handleCheckoutStep = ( step, option) => {
		this.props.handleCheckoutStep(step, option)
		if (this.state.loggedIn === true && step === 3 ) {
			this.props.handleCheckoutStep(4, "already logged in")
		}
	}

	/*should merge this with the anonymous function called to handle pick up location when choosing pickup method*/
	handlePickupLocation = ( name, value, address, pickupCode, pickupGps) => {
		//recording pick up location and changing section to pick up method
		if(address) {
			this.setState({
				[name]:value,
				activeItem:"Pick Up Method",
				pickupAddress:address,
				pickupCode:pickupCode,
				pickupGps:pickupGps,
				menuMessage:undefined
			})
		}
	}

	handlePickupMethod = ( method, methodDetail ) => {
		this.setState({
			pickupMethod:method,
			pickupMethodDetail:methodDetail,
			activeItem:"Contact Information",
			deliveryLocationCode: "",
			checkoutStage:0,
			menuMessage:undefined,
			showCheckoutDetails:true
		},()=>{
			this.scrollToCheckoutDetails()
		})
		this.handleCheckoutStep(3, method+" "+methodDetail)
	}

	toggleValue = (e, {name}) => {
		/*should change this to use previous state of this to be sure its got the correct value
		 *https://teamtreehouse.com/community/react-docs-now-recommends-using-function-with-prevstate-inside-of-setstate
		 */
		this.setState({ [name]:!this.state[name]})
	}

	saveShippingAddress = ({address, addressId, locationCode}) =>{
		//Negosyo.getDeliveryFee(locationCode);
		this.setState({
			loadingDeliveryFee:true,
			errorDeliveryFee:null,
		})
		Negosyo.getDeliveryFee(locationCode)
		.then((response)=>{
			if(response.status.toLowerCase()==="ok") {
				let deliveryFee=response.data.fee;
				let deliveryCutOff=response.data.discounttarget;
				let deliveryDiscount=response.data.discountamount;
				let discountLabel = response.data.discountlabel;
				address.locationcode = locationCode;
				this.setState({
					shippingAddress:address,
					billingAddress:address,
					shippingAddressId:addressId,
					billingAddressId:addressId,
					activeItem:"Contact Information",
					checkoutStage:0,
					menuMessage:undefined,
					deliveryLocationCode: locationCode,
					deliveryFee:deliveryFee,
					deliveryCutOff:deliveryCutOff,
					deliveryDiscount:deliveryDiscount,
					deliveryFeeFound:true,
					deliveryDiscountLabel:discountLabel,
					loadingDeliveryFee:false,
					showCheckoutDetails:true
				},()=>{
					if (this.props.handleDeliveryChange) {
						this.props.handleDeliveryChange(this.state.pickup, discountLabel, deliveryFee, deliveryDiscount, deliveryCutOff, locationCode);
					}
					this.scrollToCheckoutDetails()
				})
				this.handleCheckoutStep(3, "Address")
			}else if(response.status.toLowerCase()==="needdetail") {
				const {
					loggedIn
				} = this.state
				if(loggedIn) {
					this.setState({
						deliveryLocationCode: locationCode,
						errorDeliveryFee:(
							<React.Fragment>
								Barangay data is required to proceed.<br/>
								Click <Link to="/my-account/?active=Address" > here </Link> to update your address(es)
							</React.Fragment>
						),
						loadingDeliveryFee:false
					})
				}else {
					this.setState({
						errorDeliveryFee:"Barangay data is required to proceed",
						loadingDeliveryFee:false
					})
				}
				if (this.props.handleDeliveryChange) {
					this.props.handleDeliveryChange(this.state.pickup, "", -1, 0, 0, locationCode);
				}
			}else if(response.status.toLowerCase()==="notcovered") {
				this.setState({
					shippingAddress:address,
					billingAddress:address,
					shippingAddressId:addressId,
					billingAddressId:addressId,
					activeItem:"Contact Information",
					checkoutStage:0,
					menuMessage:undefined,
					deliveryLocationCode: locationCode,
					deliveryFeeFound:false,
					loadingDeliveryFee:false,
					showCheckoutDetails:true
				},()=>{
					if (this.props.handleDeliveryChange) {
						this.props.handleDeliveryChange(this.state.pickup, "", -2, 0, 0, locationCode);
					}
					this.scrollToCheckoutDetails()
				})
				this.handleCheckoutStep(3, "Address")
				//not covered or problem with ai
			}else {
				this.setState({
					errorDeliveryFee:"Error occured. Please try again.",
					loadingDeliveryFee:false
				})
			}
		}).catch((err)=>{
			this.setState({
				errorDeliveryFee:"Error occured. Please try again.",
				loadingDeliveryFee:false
			})
		})

		/*
		if(!this.state.diffAddress) {
			this.setState({
				shippingAddress:address,
				billingAddress:address,
				shippingAddressId:addressId,
				billingAddressId:addressId,
				activeItem:"Contact Information",
				checkoutStage:0,
				menuMessage:undefined
			},()=>{
				this.scrollToTopSection()
			})
			this.handleCheckoutStep(3, "Address")
		}else {
			this.setState({
				shippingAddress:address,
				activeItem:"Billing Address",
				shippingAddressId:addressId,
				menuMessage:undefined
			},()=>{
				this.scrollToTopSection()
			})
		}
		*/
	}


	saveBillingAddress = ({address, addressId, locationCode}) =>{
		if(this.state.shippingAddress) {
			this.setState({
				billingAddress:address,
				activeItem:"Contact Information",
				billingAddressId:addressId,
				checkoutStage:0,
				menuMessage:undefined
			},()=>{
				this.scrollToTopSection()
			})
			this.handleCheckoutStep(3, "Address")
		}else {
			this.setState({
				billingAddress:address,
				billingAddressId:addressId
			},()=>{
				this.scrollToTopSection()
			})
		}

	}

	handleBusShipping = (busDetails, stationName) => {
		this.setState({
			busDetails:busDetails,
			stationName:stationName,
			activeItem:"Contact Information",
			checkoutStage:0,
			deliveryLocationCode: "",
			menuMessage:undefined,
			showCheckoutDetails:true
		},()=>{
			this.scrollToCheckoutDetails()
		})
		this.handleCheckoutStep(3, busDetails+" "+stationName)
	}

	handleBackButton = () => {
		const {
			activeItem,
			pickup,
			diffAddress
		} = this.state
		this.setState({
			menuMessage:undefined
		})
		if(activeItem==="Pick Up Method" || activeItem==="Shipping Address" || activeItem==="Bus Shipping Details"){
			this.setState({
				activeItem:"Method"
			}, ()=>{
				this.scrollToTopSection()
			})
		}else if(activeItem==="Contact Information") {
			this.setState({
				errorContactInfo:'',
				message:''
			})
			if(pickup==="pick up") {
				this.setState({
					activeItem:"Pick Up Method"
				},()=>{
					this.scrollToTopSection()
				})
			}else if(pickup==="bus") {
				this.setState({
					activeItem:"Bus Shipping Details"
				},()=>{
					this.scrollToTopSection()
				})
			}else {
				this.setState({
					activeItem:diffAddress===true ? "Billing Address":"Shipping Address"
				},()=>{
					this.scrollToTopSection()
				})
			}
		}else {
			this.setState({
				errorCheckout:'',
				message:'',
				activeItem:"Contact Information"
			}, ()=>{
				this.scrollToTopSection()
			})
		}

	}

	changeGuestCheckoutStage = (newStage) =>{
		this.setState({
			guestCheckOutStage:newStage
		},()=>{
			this.scrollToTopSection()
		})
		this.handleCheckoutStep(4, newStage)

	}

	formatCurrency = (number) => {
		const options = {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		};
		return Number(number).toLocaleString('en', options);
	}

	handleChangeTerms = (newAgreeTerms) => {
		this.setState({
			agreeTerms:newAgreeTerms
		})
	}

	handleContactInfo = () => {
		const {
			contact,
			name,
			loggedIn,
		} = this.state
		const {
			resetErrorCheckout
		} = this.props
		//this.handleCheckoutStep(5, "submission")
		resetErrorCheckout();
		this.setState({
			menuMessage:undefined,
			loadingContactInfo:true,
			errorContactInfo:''
		})
		const tempState = {
			name:name,
			contact:contact
		}
		const check = this.validateInput(tempState)
		if(check) {
			if( loggedIn ) {
				this.setState({
					loadingContactInfo:false,
					activeItem:'Checkout'
				},()=>{
					this.scrollToCheckoutDetails()
				})
			} else { // guest checkout, secret sign up/resend code for OTP
				Negosyo.getUser(contact)
				.then((user)=>{
					if(user.status==="Active" ){
						if(user.logintype==="Password") {
							clearTimeout(this.state.timeOut1)
							const timeOut1 = setTimeout( ()=>{
								this.setState({errorContactInfo:''})
							}, 8000)

							this.setState({
								loadingContactInfo:false,
								errorContactInfo:"Account already exists. Please sign in to proceed.",
								timeOut1:timeOut1
							})
						}else {
							this.setState({
								loadingContactInfo:false,
								activeItem:'Checkout'
							},()=>{
								this.scrollToCheckoutDetails()
							})
						}

					}else if(user.status==="Inactive") {
						/* forgot password will handle the Inactive and aws missing bug, so just direct them to forgot-password
						 * like in the forgot-password Inactive handling this is a temporary measure to handle the fall out
						 * because we don't handle when aws register or aws login w/ otp fails
						 */
						if( user.logintype==="Password" ) {
							this.setState({
								loadingContactInfo:false,
								message:(
									<React.Fragment>
										There was an attempt to use this number in the past.  For your safety, kindly reset your password <Link to="/forgot-password/" >here</Link>
									</React.Fragment>
								)
							})
						}else {
							this.setState({
								loadingContactInfo:false,
								activeItem:'Checkout'
							},()=>{
								this.scrollToCheckoutDetails()
							})
						}


					}else if(user.status==="None") {
						this.setState({
							loadingContactInfo:false,
							activeItem:'Checkout'
						},()=>{
							this.scrollToCheckoutDetails()
						})
					}else {
						this.setState({
							loadingContactInfo:false,
							errorContactInfo:"Error occurred, please try again.",
						})
					}
				}).catch((err)=>{
					this.setState({
						loadingContactInfo:false,
						errorContactInfo:"Error occurred, please try again.",
					})
				})

			}
		}else {
			this.setState({loadingContactInfo:false})
		}

	}

	//send code if guest checkout or handle checkout if signed in
	handleCheckout = () => {
		const {handleCheckout, resetErrorCheckout} = this.props
		const {
			diffAddress,
			pickup,
			contact,
			name,
			loggedIn,
			agreeTerms
		} = this.state
		let username
		if(this.state.user) {
			username = this.state.user.username
		}
		this.handleCheckoutStep(5, "submission")
		resetErrorCheckout();
		this.setState({
			menuMessage:undefined,
			errorCheckout:'',
		})
		if(!agreeTerms) {
			this.setState({
				errorCheckout:"You must agree with the terms and conditions",
			})
			return 0;
		}

		if( loggedIn ) {
			this.setState({
				loadingCheckout:true
			})
			/*commented out getRelevantToken since it wasn't used anyway but will leave here just in case we will need it in the future for now*/
			//Negosyo.getRelevantToken()
			//.then((token)=>{
				const customerDetail = {
					name:this.state.name,
					contact:this.state.contact,
				}
				if( this.state.email) {
					customerDetail.email = this.state.email
				}
				if(pickup==="delivery") {
					let billingAddress = this.state.billingAddress
					let shippingAddress = this.state.shippingAddress
					const {
						deliveryLocationCode,
						deliveryFee,
						deliveryDiscount,
						deliveryCutOff,
						deliveryFeeFound
					} = this.state
					let deliveryFeeDetails;
					if(deliveryFeeFound===true) {
						deliveryFeeDetails= {
							fee:deliveryFee,
							discountTarget:deliveryCutOff,
							discountAmount:deliveryDiscount,
							locationcode:deliveryLocationCode
						}
					}else {
						deliveryFeeDetails= {
							fee:0,
							locationcode:deliveryLocationCode
						}
					}
					if(diffAddress) {
						handleCheckout({billingAddress, shippingAddress, username, customerDetail, deliveryFeeDetails})
						.then(()=>{
							this.setState({loadingCheckout:false})
						})
					} else {
						//pass token to checkout
						handleCheckout({billingAddress, username, customerDetail, deliveryFeeDetails})
						.then(()=>{
							this.setState({loadingCheckout:false})
						})
					}
				}else {
					let pickupMethod
					if(pickup==="pick up") {
						pickupMethod = this.state.pickupMethodDetail
					}else if(pickup==="bus") {
						pickupMethod = `${this.state.busDetails} ${this.state.stationName}`
					}
					//pass token to checkout
					const pickupCode = this.state.pickupCode;

					handleCheckout({customerDetail, pickupCode, username, pickupMethod})
					.then(()=>{
						this.setState({loadingCheckout:false})
					})
				}
			//}).catch((err)=>{
			//	this.setState({loadingCheckout:false})
				//shouldn't reach here
			//})

		} else { // guest checkout, secret sign up/resend code for OTP
			this.setState({loadingCheckout:true})
			Negosyo.getUser(contact)
			.then((user)=>{
				if(user.status==="Active" ){
					if(user.logintype==="Password") {
						clearTimeout(this.state.timeOut1)
						const timeOut1 = setTimeout( ()=>{
							this.setState({errorCheckout:''})
						}, 8000)

						this.setState({
							loadingCheckout:false,
							errorCheckout:"Account already exists. Please sign in to proceed.",
							timeOut1:timeOut1
						})
					}else {
						Negosyo.getOTPCode(contact, user.username)
						.then((response)=>{
							if(response.status==="OK") {
								//this.setState({countdown:60})
								this.setState({
									guestAuthCode:response.otp
								},()=>{
									this.confirmGuestCheckout()
								})
								/*
								this.setState({
									loadingCheckout:false,
									checkoutStage:1
								})
								*/
								//alert("OTP sent to your contact number")
							}else {
								this.setState({
									loadingCheckout:false,
									errorCheckout:"Error occurred, please try again.",
								})
								//alert({error:data.message})
							}

						}).catch(e =>{
							this.setState({
								loadingCheckout:false,
								errorCheckout:"Error occurred, please try again.",
							})
						})
						/*
						this.setState({
							checkoutStage:1,
							loadingCheckout:false
						})
						*/
					}

				}else if(user.status==="Inactive") {
					/* forgot password will handle the Inactive and aws missing bug, so just direct them to forgot-password
					 * like in the forgot-password Inactive handling this is a temporary measure to handle the fall out
					 * because we don't handle when aws register or aws login w/ otp fails
					 */
					if( user.logintype==="Password" ) {
						this.setState({
							loadingCheckout:false,
							message:(
								<React.Fragment>
									There was an attempt to use this number in the past.  For your safety, kindly reset your password <Link to="/forgot-password/" >here</Link>
								</React.Fragment>
							)
						})
					}else {
						/*Negosyo.register is for otp users that are missing aws account and this is a stopgap measure to fix the missing aws account bug
						 */
						const password = ( Math.floor(Math.random()*9000000)+1000000 ).toString()
						Negosyo.register(user.username, password )
						.then(()=>{
							Negosyo.getOTPCode(contact, user.username)
							.then((response)=>{
								if(response.status==="OK") {
									//this.setState({countdown:60})
									this.setState({
										guestAuthCode:response.otp
									},()=>{
										this.confirmGuestCheckout()
									})
									/*
									this.setState({
										loadingCheckout:false,
										checkoutStage:1
									})
									*/
									//alert("OTP sent to your contact number")
								}else {
									this.setState({
										loadingCheckout:false,
										errorCheckout:"Error occurred, please try again.",
									})
									//alert({error:data.message})
								}

							}).catch(e =>{
								this.setState({
									loadingCheckout:false,
									errorCheckout:"Error occurred, please try again.",
								})
							})
							/*
							this.setState({
								checkoutStage:1,
								loadingCheckout:false
							})
							*/
						}).catch(e =>{
							if(
								e.message.toLowerCase()==="invalid lambda function output : invalid json" ||
								e.message.toLowerCase()==="user already exists"
							){
								Negosyo.getOTPCode(contact, user.username)
								.then((response)=>{
									if(response.status==="OK") {
										//this.setState({countdown:60})
										this.setState({
											guestAuthCode:response.otp
										},()=>{
											this.confirmGuestCheckout()
										})
										/*
										this.setState({
											loadingCheckout:false,
											checkoutStage:1
										})
										*/
										//alert("OTP sent to your contact number")
									}else {
										this.setState({
											loadingCheckout:false,
											errorCheckout:"Error occurred, please try again.",
										})
										//alert({error:data.message})
									}

								}).catch(e =>{
									this.setState({
										loadingCheckout:false,
										errorCheckout:"Error occurred, please try again.",
									})
								})
								/*
								this.setState({
									checkoutStage:1,
									loadingCheckout:false
								})
								*/
							}else {
								this.setState({
									loadingCheckout:false,
									errorCheckout:"Error occurred, please try again.",
								})
								//alert(e.message)
							}
						})
					}


				}else if(user.status==="None") {
					Negosyo.registerUser(contact, "OTP", name)
					.then((data)=>{
						const password = ( Math.floor(Math.random()*9000000)+1000000 ).toString()
						if(data.status==="OK") {
							Negosyo.register(data.username, password )
							.then(()=>{
								this.setState({
									guestAuthCode:data.otp
								},()=>{
									this.confirmGuestCheckout()
								})

								/*
								this.setState({
									checkoutStage:1,
									loadingCheckout:false
								})
								*/
							}).catch(e =>{
								if(e.message.toLowerCase()==="invalid lambda function output : invalid json"){
									//act like everything is normal
									this.setState({
										guestAuthCode:data.otp
									},()=>{
										this.confirmGuestCheckout()
									})
									/*
									this.setState({
										checkoutStage:1,
										loadingCheckout:false
									})
									*/
								}else {
									this.setState({
										loadingCheckout:false,
										errorCheckout:"Error occurred, please try again.",
									})
									//alert(e.message)
								}
							})

						}else {
							this.setState({
								loadingCheckout:false,
								errorCheckout:"Error occurred, please try again.",
							})
						}
					}).catch((e)=>{
						this.setState({
							loadingCheckout:false,
							errorCheckout:"Error occurred, please try again.",
						})
					})
				}else {
					this.setState({
						loadingCheckout:false,
						errorCheckout:"Error occurred, please try again.",
					})
				}
			}).catch((err)=>{
				this.setState({
					loadingCheckout:false,
					errorCheckout:"Error occurred, please try again.",
				})
			})

		}
	}

	confirmGuestCheckout = () => {
		const check = true//this.validateInput({guestAuthCode:this.state.guestAuthCode}) //uncomment this when we require user to input otp when doing guest checkout
		if(check) {
			this.setState({loadingCheckout:true})

			const {
				pickup,
				guestAuthCode
			} = this.state
			const {
				contact
			} = this.state
			Negosyo.getUser(contact)
			.then((data)=>{
				//only Active or Inactive Accounts should reach here
				if(data.status==="Active" || data.status==="Inactive") {

					Negosyo.loginOTP(data.username, `${guestAuthCode}_${contact}`)
					.then(()=>{
						let username
						username = data.username
						/*commented out getRelevantToken since it wasn't used anyway but will leave here just in case we will need it in the future for now*/
						//Negosyo.getRelevantToken()
						//.then((token)=>{
							const { handleCheckout } = this.props
							const customerDetail = {
								name:this.state.name,
								contact:this.state.contact,
							}
							if( this.state.email) {
								customerDetail.email = this.state.email
							}
							if(pickup==="delivery") {
								const {
									diffAddress
								} = this.state

								let billingAddress = this.state.billingAddress
								let shippingAddress = this.state.shippingAddress
								const {
									deliveryLocationCode,
									deliveryFee,
									deliveryDiscount,
									deliveryCutOff,
									deliveryFeeFound
								} = this.state
								let deliveryFeeDetails;
								if(deliveryFeeFound===true) {
									deliveryFeeDetails= {
										fee:deliveryFee,
										discountTarget:deliveryCutOff,
										discountAmount:deliveryDiscount,
										locationcode:deliveryLocationCode
									}
								}else {
									deliveryFeeDetails= {
										fee:0,
										locationcode:deliveryLocationCode
									}
								}

								if(diffAddress) {
									handleCheckout({billingAddress, shippingAddress, username, customerDetail, deliveryFeeDetails})
									.then(()=>{
										this.setState({loadingCheckout:false})
										Negosyo.logOut()
										.catch((err)=>{

										})
									})
								} else {

									handleCheckout({billingAddress, username, customerDetail, deliveryFeeDetails})
									.then(()=>{
										this.setState({loadingCheckout:false})
										Negosyo.logOut()
										.catch((err)=>{

										})
									})
								}
							}else {

								//pass token to checkout]
								const pickupCode = this.state.pickupCode;
								let pickupMethod
								if(pickup==="pick up") {
									pickupMethod = this.state.pickupMethodDetail
								}else if(pickup==="bus") {
									pickupMethod = `${this.state.busDetails} ${this.state.stationName}`
								}

								handleCheckout({customerDetail, pickupCode, username, pickupMethod})
								.then(()=>{
									this.setState({loadingCheckout:false})
									Negosyo.logOut()
									.catch((err)=>{

									})
								})
							}
						//}).catch((err)=>{
						//	this.setState({loadingCheckout:false})
							//shouldn't reach here
						//})

					}).catch(e =>{
						this.setState({
							loadingCheckout:false,
							errorCheckout:"Error occurred, please try again.",
						})

						//this.setState({loadingCheckout:false, errorGuestCheck:"Wrong OTP"})

					})
				}else {
					this.setState({
						loadingCheckout:false,
						errorCheckout:"Error occurred, please try again.",
					})
					//console.log("IDK how it got here, since only way to reach here is successfully making an account")
				}
			}).catch((err)=>{

				this.setState({
					loadingCheckout:false,
					errorCheckout:"Error occurred, please try again.",
				})
			})
		}else {
			this.setState({
				loadingCheckout:false,
				errorCheckout:"Error occurred, please try again.",
			})
		}

	}

	getDeliveryInfo = (mobileview, showorgleveldeliveryfees,
						feename, deliveryFeeFound,
						priceCurrency, actualDeliveryFee, deliveryFee, deliveryDiscount, deliveryDiscountDiff, discountReached, deliveryDiscountLabel, grandTotal, recomputationwarning) => {
		return <>
			<span name="checkout-details" className={styles.anchor}/>
			{(showorgleveldeliveryfees === false && deliveryFeeFound && discountReached) && <>
				<div className={styles.checkoutInfoMarginTop}>
					<b>{feename.replace("Total ","")+" Sub-total"}</b>:<span className={styles.currency}>{priceCurrency+" "+this.formatCurrency(deliveryFee)}</span><br/>
				</div>
				<div className={styles.checkoutInfoMarginTop}>
					<b>{"Delivery Discounts"}</b>:<span className={styles.currency}>{"("+priceCurrency+" "+this.formatCurrency(deliveryDiscount)+")"}</span><br/>
				</div>

			</>}

			<div className={styles.checkoutInfoMarginTop}>
				<b>{feename}</b>:<span className={styles.currency}>{actualDeliveryFee}</span><br/>
			</div>
			{(showorgleveldeliveryfees === false && deliveryFeeFound && !discountReached) &&
				<Message className={styles.deliveryDiscountMessage} compact>
					<span className={styles.italic}>{deliveryDiscountLabel} when <br/> you add <b>{deliveryDiscountDiff}</b> or more </span>
				</Message>
			}
			{mobileview && <Divider className={styles.dividerBlack}/> }
			<div className={styles.checkoutInfoMarginTop}>
				<b className={styles.grandTotal}>Grand Total</b>:
				<span className={styles.currency}>{grandTotal}</span>
			</div>
			{recomputationwarning!=="" && (
				<b className={styles.italic}>{recomputationwarning}</b>
			)}
		</>
	}

	render() {
		const {
			loggedIn,
			shippingAddress,
			//billingAddress,
			email,
			activeItem,
			//diffAddress,
			pickup,
			pickupOptions,
			pickupDetail,
			pickupAddress,
			pickupCode,
			pickupMethod,
			pickupMethodDetail,
			loadingPickup,
			contact,
			name,
			error,
			guestCheckOutStage,
			loadingCheckout,
			checkoutStage,
			//guestAuthCode,
			//signInUp,
			//signUpStage,
			//signUpContact,
			//signUpPassword,
			//proper,
			//transition,
			user,
			shippingAddressId,
			//billingAddressId,
			//errorGuestCheck,
			pickupGps,
			stationName,
			busDetails,
			loadingProfile,
			loadingContact,
			agreeTerms,
			menuMessage,
			deliveryFee,
			deliveryDiscount,
			deliveryCutOff,
			deliveryDiscountLabel,
			loadingDeliveryFee,
			errorDeliveryFee,
			deliveryFeeFound,
			loadingContactInfo,
			errorContactInfo,
			showCheckoutDetails
		} = this.state

		const {
			saveShippingAddress,
			//saveBillingAddress,
			handleItemClick,
			//toggleValue, uncomment if needs toggle of some state like diffAddress
			handleCheckout,
			handleCheckoutStep,
			handlePickupLocation,
			handleInputChange,
			//resendCode, //uncomment if need to add back the guest checkout part where they verify they give otp for checkout(sign in with otp)
			//confirmGuestCheckout, //uncomment if need to add back the guest checkout part where they verify they give otp for checkout(sign in with otp)
			//toggleStage, //uncomment this if we will need to verify contact(AuthForm) when user registers
			handlePickupMethod,
			handleUserLogIn,
			handleBackButton,
			handleBusShipping,
			changeGuestCheckoutStage,
			scrollToTopSection,
			handleChangeTerms,
			formatCurrency,
			handleContactInfo
		} = this

		const {
			showorgleveldeliveryfees
		} = this.props

		let price = this.props.display_price.with_tax.formatted;
		let priceAmount = this.props.display_price.with_tax.amount;
		let priceCurrency = this.props.display_price.with_tax.currency;

		if(this.props.items.length===0) {
			return ""
		} else if(this.props.productAvailability===false) {
			return (
				<Message negative className={styles.msg} >
					Please address errors indicated above to proceed
				</Message>
			)
		}

		/* in case the actual items are needed for cart summary
		let items =[];
		for(let i=0; i< this.props.items.length; i++ ) {
			items = items.concat(this.props.items[i].data)
		}
		*/

		let contactSubTitle = ""
		if(pickup==="delivery") {
			contactSubTitle="Contact Details of Person to Receive"
		}else if(pickup==="pick up" ) {
			if(pickupMethod==="Personal Pick Up") {
				contactSubTitle="Contact Details of Person to Pick up"
			}else if(pickupMethod==="Courier"){
				contactSubTitle="Contact Details of Person for Coordination"
			}
		}else if(pickup==="bus") {
			contactSubTitle="Contact Details of Person for Coordination"
		}
		let checkoutButtonMsg
		if(this.props.openList.length> 0) {
			checkoutButtonMsg="Please finalize quantities"
		}
		if(this.props.disableCheckout && !loadingCheckout) { //loadingAPi is not null and its not checkout that's being called
			checkoutButtonMsg="Please wait for editing of your cart to finish"
		}
		const recomputationwarning=((pickup==="bus" || (pickup === "delivery" && deliveryFeeFound===false))?"Subject to recomputation":"");
		let feename = "Total Delivery Fees";
		let MethodDetailName = "Method Details"
		if(pickup==="pick up") {
			MethodDetailName="Pick Up Method"
		}else if(pickup==="delivery"){
			MethodDetailName="Shipping Address"
		}else if(pickup==="bus") {
			MethodDetailName="Bus Shipping Details"
			feename = "Total Processing Fees"
		}
		let discountReached = priceAmount >= deliveryCutOff;
		let actualDeliveryFee = "For Assessment"; // pickup==="bus" || deliveryFeeFound===false
		if(pickup==="pick up") {
			actualDeliveryFee = "N/A"; //`${priceCurrency} 0.00`;
		}else if (pickup!=="bus" && deliveryFeeFound) {
			if (showorgleveldeliveryfees) {
				actualDeliveryFee = `${priceCurrency} ${formatCurrency(this.props.deliveryTotal)}`;
			} else {
				actualDeliveryFee = discountReached ? `${priceCurrency} ${formatCurrency(deliveryFee-deliveryDiscount)}`:`${priceCurrency} ${formatCurrency(deliveryFee)}`
			}
		}
		let grandTotal = price;
		if (pickup!=="bus" && pickup!=="pick up" && deliveryFeeFound) {
			if (showorgleveldeliveryfees) {
				grandTotal = `${priceCurrency} ${formatCurrency(priceAmount+this.props.deliveryTotal)}`;
			} else {
				grandTotal = discountReached ? `${priceCurrency} ${formatCurrency(priceAmount+deliveryFee-deliveryDiscount)}`:`${priceCurrency} ${formatCurrency(priceAmount+deliveryFee)}`;
			}
		}
		let deliveryDiscountDiff = "";
		if(deliveryFeeFound && !discountReached) {
			deliveryDiscountDiff= `${priceCurrency} ${formatCurrency(deliveryCutOff-priceAmount)}`;
		}

		return (
			<div className={styles.overallDiv}>
				<Divider className={styles.divider}/>
				<span name="aboveCheckoutDetails" className={styles.anchor}/>
				<Grid>
					<Grid.Column computer={9} tablet={8} mobile={6} className={`${styles.item} ${styles.desktopOnlyMod} ${styles.checkoutDetails}`}>
						{showCheckoutDetails && (
							<React.Fragment>
								{ pickup==="delivery" && (
									<React.Fragment>
										<b className={styles.text}>Delivery Details:</b>
										<div className={styles.checkoutInfoMarginTop}><ShowNewAddress address={shippingAddress}/></div>
									</React.Fragment>
								)}
								{pickup==="pick up" && (
									<React.Fragment>
										<b className={styles.text}>Pickup Details:</b>
										<div className={styles.checkoutInfoMarginTop}>
											Pickup at {pickupDetail}<br/>
											{pickupMethodDetail}
										</div>
									</React.Fragment>
								)}
								{pickup==="bus" && (
									<React.Fragment>
										<b className={styles.text}>Bus Shipping Details:</b>
										<div className={styles.checkoutInfoMarginTop}>{busDetails}, {stationName} </div>
									</React.Fragment>
								)}
								{activeItem==="Checkout" && (
									<div>
										{name}, {contact}{email && (<React.Fragment>, {email}</React.Fragment>)}
									</div>
								)}
							</React.Fragment>
						)}
					</Grid.Column>
					<Grid.Column computer={7} tablet={8} mobile={10} className={`${styles.text} ${styles.textAlignRight} ${styles.desktopOnlyMod}`}>
						<div>
							<b>Items Total:</b>
							<span className={styles.currency}>{` ${price}`}</span>
						</div>
						{showCheckoutDetails && (
							<React.Fragment>
								<span name="checkout-details" className={styles.anchor}/>
								{this.getDeliveryInfo(false, showorgleveldeliveryfees,
										feename,
										deliveryFeeFound,
										priceCurrency, actualDeliveryFee, deliveryFee, deliveryDiscount, deliveryDiscountDiff, discountReached, deliveryDiscountLabel, grandTotal, recomputationwarning)}
							</React.Fragment>
						)}
					</Grid.Column>
					<Grid.Column mobile={16} className={`${styles.text} ${styles.mobileOnlyMod}`}>
						<div className={styles.textAlignRight}>
							<b>Items Total:</b>
							<span className={styles.currency}>{` ${price}`}</span>
						</div>

						{showCheckoutDetails && (
							<React.Fragment>
								<Divider className={styles.dividerBlack}/>
								<div className={`${styles.checkoutInfoMarginTop} ${styles.item} ${styles.checkoutDetails}`}>
									{ pickup==="delivery" && (
										<ShowNewAddress address={shippingAddress}/>
									)}
									{pickup==="pick up" && (
										<div>
											Pickup at {pickupDetail}, {pickupMethodDetail}
										</div>
									)}
									{pickup==="bus" && (
										<div>
											Bus Shipping, {busDetails}, {stationName}
										</div>
									)}
									{activeItem==="Checkout" && (
										<div>
											{name}, {contact}{email && (<React.Fragment>, {email}</React.Fragment>)}
										</div>
									)}
								</div>
								<span name="checkout-details" className={styles.anchor}/>
								<div className={styles.textAlignRight}>
									{this.getDeliveryInfo(true, showorgleveldeliveryfees,
											feename,
											deliveryFeeFound,
											priceCurrency, actualDeliveryFee, deliveryFee, deliveryDiscount, deliveryDiscountDiff, discountReached, deliveryDiscountLabel, grandTotal, recomputationwarning)}
								</div>
							</React.Fragment>
						)}
					</Grid.Column>
				</Grid>
				<div className={styles.checkoutMargin}>
					<Grid>
						<Grid.Column width={4} className={styles.gridMenu} stretched>
							<Menu fluid vertical tabular >
								<Menu.Item
									name="Method"
									active={activeItem==='Method'}
									onClick={handleItemClick}
									className={styles.menuItem}
									content={"1.) Method"}
								/>
								{menuMessage==="Method" && (
									<div className={styles.menuItemMessage}>
										Please click a method from the list
									</div>
								)}
								<Menu.Item
									name={MethodDetailName}
									active={activeItem===MethodDetailName}
									onClick={handleItemClick}
									disabled={pickup===undefined}
									className={styles.menuItem}
									content={`2.) ${MethodDetailName}`}
								/>
								{menuMessage===MethodDetailName && (
									<div className={styles.menuItemMessage}>
										{`Please provide ${MethodDetailName}`}
									</div>
								)}

								<Menu.Item
									name="Contact Information"
									active={activeItem==='Contact Information'}
									onClick={handleItemClick}
									className={styles.menuItem}
									disabled= {activeItem!=="Contact Information" && activeItem!=='Checkout'}
									content={"3.) Contact Information"}
								/>
								{menuMessage==="Contact Information" && (
									<div className={styles.menuItemMessage}>
										Please provide contact details
									</div>
								)}
								<Menu.Item
									name="Checkout"
									active={activeItem==='Checkout'}
									onClick={handleItemClick}
									className={styles.menuItem}
									disabled={activeItem!=="Checkout"}
									content={"4.) Checkout"}
								/>

							</Menu>
						</Grid.Column>
						<Grid.Column width={12} id="section" className={styles.sectionPaddingTop}>
							<span id="section-top" className={styles.anchor}/>
							<Header className={styles.headerMod}>{activeItem}</Header>
							<div>
								{ activeItem==="Method" && (
									<React.Fragment>
										{pickupOptions && !loadingPickup ?  (
											<PickUpForm
												address={pickupAddress}
												items={pickupOptions}
												value={pickup==="pick up" ? pickupDetail:pickup}
												handlePickUp={(name, value, address, pickupCode, pickupGps)=>{
													this.setState({
														pickup:"pick up",
														showCheckoutDetails:false,
														deliveryFeeFound:null
													},()=>{
														if (this.props.handleDeliveryChange) {
															this.props.handleDeliveryChange(this.state.pickup, "", -99, 0, 0, "");
														}
														this.scrollToCheckoutDetails()
													})
													handleCheckoutStep(2, "Pick Up "+pickupCode)
													handlePickupLocation(name, value, address, pickupCode, pickupGps)
													handleChangeTerms(true)

												}}
												handleDelivery={()=>{
													this.setState({
														pickup:"delivery",
														activeItem:"Shipping Address",
														menuMessage:undefined,
														showCheckoutDetails:false,
														deliveryFeeFound:null
													}, ()=>{
														if (this.props.handleDeliveryChange) {
															this.props.handleDeliveryChange(this.state.pickup, "", 0, 0, 0, "");
														}
														this.scrollToCheckoutDetails()
													})
													handleCheckoutStep(2, "Delivery")
													handleChangeTerms(true)

												}}
												handleBus={(value, pickupCode)=>{
													this.setState({
														activeItem:"Bus Shipping Details",
														pickup:"bus",
														pickupCode:pickupCode,
														menuMessage:undefined,
														showCheckoutDetails:false,
														deliveryFeeFound:null
													}, ()=> {
														if (this.props.handleDeliveryChange) {
															this.props.handleDeliveryChange(this.state.pickup, "", 0, 0, 0, "");
														}
														this.scrollToCheckoutDetails()
													})
													handleCheckoutStep(2, "Bus Shipping")
													handleChangeTerms(false)

												}}
												pickupCode={pickupCode}
												name="pickupDetail"
												pickupGps={pickupGps}
											/>
										):(
											<Loader active inline="centered" />
										)}
									</React.Fragment>
								)}
								{ activeItem==="Pick Up Method" && (
									<PickUpMethodForm
										handleSubmit={handlePickupMethod}
										backFunc={handleBackButton}
										value={pickupMethod}
										courierDetail={pickupMethodDetail}
									/>
								)}
								{ activeItem==="Bus Shipping Details" && (
									<BusShippingForm
										handleSubmit={handleBusShipping}
										backFunc={handleBackButton}
										busDetails={busDetails}
										stationName={stationName}
									/>
								)}
								{ activeItem==="Shipping Address" && (
									<Grid divided='vertically'>
										<Grid.Column width={16}>
											{ (loggedIn!==true ) &&(
												<React.Fragment>
													<AddressForm
														handleSubmit={saveShippingAddress}
														values={shippingAddress}
														buttonMessage={"Proceed"}
														backFunc={handleBackButton}
														loading={loadingDeliveryFee}
														error={errorDeliveryFee}
													/>
												</React.Fragment>
											)}
											{loggedIn===true && (
												<React.Fragment>
													<ShowAddress
														user={user}
														handleChoose={saveShippingAddress}
														value={shippingAddressId}
														backFunc={handleBackButton}
														transitionFunc={()=>{
															scrollToTopSection()
															this.setState({
																menuMessage:undefined
															})
														}}
														loadingProp={loadingDeliveryFee}
														errorProp={errorDeliveryFee}
													/>
													<br/>
												</React.Fragment>
											)}
										</Grid.Column>
									</Grid>
								)}
								{ activeItem==="Contact Information" && (
									<React.Fragment>
										{ checkoutStage===0 && (
											<React.Fragment>
												{ (loggedIn || guestCheckOutStage==="checkout") && (
													<React.Fragment>
														<Segment>

															<Header className={styles.header} name={"contactInfoCheckoutHeader"}>
																Contact Person Details
																<Header.Subheader>
																	{contactSubTitle}
																</Header.Subheader>
															</Header>

															<Form>
																<Form.Input
																	label="Full Name*"
																	placeholder="Your Full name"
																	value={name}
																	error={error.name}
																	name="name"
																	onChange={handleInputChange}
																	className={styles.form}
																	disabled={loadingProfile || loadingContactInfo}
																	loading={loadingProfile}
																/>
																<Form.Input
																	label="Mobile #*"
																	placeholder="(e.g. +639XXXXXXXXX, 09XXXXXXXXX)"
																	value={contact}
																	error={error.contact}
																	name="contact"
																	onChange={handleInputChange}
																	className={styles.form}
																	disabled={loadingContact || loadingContactInfo}
																	loading={loadingContact}
																/>
																<Form.Input
																	label="Email"
																	placeholder="Email"
																	value={email}
																	name="email"
																	type="email"
																	onChange={handleInputChange}
																	className={styles.form}
																	disabled={loadingContact || loadingContactInfo}
																	loading={loadingContact}
																/>
															</Form>
														</Segment>
														{this.state.errorContactInfo &&
															<Message warning className={styles.msg} >
																<Message.Header name={"checkoutErrorMsg"}>{this.state.errorContactInfo}</Message.Header>
															</Message>
														}
														{this.state.message &&
															<Message className={styles.msg} >
																<Message.Header name={"checkoutMsg"}>{this.state.message}</Message.Header>
															</Message>
														}
														<Button
															content={"Back"}
															color={backColor}
															onClick={handleBackButton}
															disabled={loadingContactInfo}
															className={styles.form}
															name={"checkoutBackButton"}
														/>
														<Button
															content={"Proceed"}
															onClick={handleContactInfo}
															color={confirmationColor}
															loading={loadingContactInfo}
															disabled={loadingContactInfo}
															className={styles.mainButton}
															name={"checkoutProceedButton"}
														/>

													</React.Fragment>
												)}
												{!loggedIn && (
													<React.Fragment>
														{guestCheckOutStage==="sign up" && (
															<React.Fragment>
																<SignUpForm
																	loginType="Password"
																	backFunc={handleBackButton}
																	handleSubmit={()=>{
																		handleUserLogIn()
																	}}
																/>
															</React.Fragment>
														)}
														{guestCheckOutStage==="sign in" && (
															<SignInForm
																backFunc={handleBackButton}
																handleSubmit={()=>{
																	handleUserLogIn()
																}}
															/>
														)}
														<div>
															<br/>
															<p className={styles.text}>
																{guestCheckOutStage !=="sign up" && (
																	<React.Fragment>
																		No account yet?
																		<Button
																			content="Sign Up"
																			className={styles.mainButton}
																			color={secondaryButtonColor}
																			onClick={()=>{
																				changeGuestCheckoutStage("sign up")
																			}}
																			name={"switchToSignUp"}
																		/>
																		<br/>
																	</React.Fragment>
																)}
																{guestCheckOutStage !=="sign in" && (
																	<React.Fragment>
																		Have an Account?
																		<Button
																			content="Sign in"
																			className={styles.mainButton}
																			color={secondaryButtonColor}
																			onClick={()=>{
																				changeGuestCheckoutStage("sign in")
																			}}
																			name={"switchToSignIn"}
																		/>
																		<br/>
																	</React.Fragment>
																)}
																{guestCheckOutStage !=="checkout" && (
																	<React.Fragment>
																		Proceed without an account?
																		<Button
																			content="Checkout as Guest"
																			className={styles.mainButton}
																			color={redirectGuestCheckoutColor}
																			onClick={()=>{
																				changeGuestCheckoutStage("checkout")
																			}}
																			name={"switchToCheckout"}
																		/>
																	</React.Fragment>
																)}
															</p>
														</div>
													</React.Fragment>
												)}

											</React.Fragment>
										)}
									</React.Fragment>
								)}
								{ activeItem==="Checkout" && (
									<React.Fragment>
										<div className={styles.text} name="terms&Conditions">
											{pickup==="bus" && (
												<React.Fragment>
													Please be reminded of the following Procedures, Terms and Conditions relevant to Bus Shipping:
													<ol>
														<li>
															Order will have to be reviewed and checked with the shipper first for rates, requirements and feasibility.
														</li>
														<li>
															Containers, such as styrofoam coolers or boxes, used for transport will be charged accordingly.
														</li>
														<li>
															Total amount due with payment instructions will be provided to the customer once available.
														</li>
														<li>
															Processing will start only after clearance of the full amount on the agreed payment facility.
														</li>
														<li>
															Transaction is considered completed upon turnover of packages to the forwarder or it's representative.
														</li>
														<li>
															Additional charges, tampering, pilferage, spoilage, and/or other incidents during shipping will be resolved by the customer directly with the forwarder.
														</li>
													</ol>
													<Checkbox
														label={<label htmlFor="agreeTerms">I hereby acknowledge that I have read, understand and agree to the <a href={'/terms'} target="_blank" rel="noopener noreferrer">Terms and Conditions </a> of NegosyoNow.com, with particular attention to the items above.</label>}
														checked={agreeTerms}
														onChange={()=>{
															handleChangeTerms(!agreeTerms)
														}}
														id="agreeTerms"
														name="agreeTerms"
													/>

												</React.Fragment>
											)}
											{pickup!=="bus" && (
												<React.Fragment>
													By clicking the Proceed button below, I hereby acknowledge that I have read,
													understand and agree to the <a href={'/terms'} target="_blank" rel="noopener noreferrer">
													Terms and Conditions </a> of NegosyoNow.com
												</React.Fragment>
											)}
										</div>

										{this.state.errorCheckout &&
											<Message warning className={styles.msg} >
												<Message.Header name={"checkoutErrorMsg"}>{this.state.errorCheckout}</Message.Header>
											</Message>
										}
										{this.state.message &&
											<Message className={styles.msg} >
												<Message.Header name={"checkoutMsg"}>{this.state.message}</Message.Header>
											</Message>
										}
										{this.props.errorCheckout &&
											<Message negative className={styles.msg} >
												<Message.Header name={"checkoutErrorMsg"}>{this.props.errorCheckout}</Message.Header>
												Please try again
											</Message>
										}
										<Button
											content={"Back"}
											color={backColor}
											onClick={handleBackButton}
											disabled={loadingCheckout}
											className={styles.form}
											name={"checkoutBackButton"}
										/>
										{ checkoutButtonMsg ? (
											<Button
											 as="div"
											 labelPosition="right"
											>
												<Button
													content={"Proceed"}
													onClick={handleCheckout}
													color={confirmationColor}
													loading={loadingCheckout}
													disabled={loadingCheckout || this.props.disableCheckout || this.props.openList.length >0}
													className={styles.checkoutButton}
													name={"checkoutProceedButton"}
												/>
												<Label
													basic
													content={checkoutButtonMsg}
													pointing="left"
													className={styles.label}
												/>
											</Button>
										):(
											<Button
												content={"Proceed"}
												onClick={handleCheckout}
												color={confirmationColor}
												loading={loadingCheckout}
												disabled={loadingCheckout || this.props.disableCheckout || this.props.openList.length >0}
												className={styles.mainButton}
												name={"checkoutProceedButton"}
											/>
										)}
									</React.Fragment>
								)}
							</div>
						</Grid.Column>
					</Grid>
				</div>
			</div>
		)
	}
}
export default CartSummary

/*  contact info when it was still contact info + terms and conditions, if for some reason need to implement this again, need to put back the validateInput in handleCheckoout
{pickup==="bus" && (
															<div className={styles.text}>
																Please be reminded of the following Procedures, Terms and Conditions relevant to Bus Shipping:
																<ol>
																	<li>
																		Order will have to be reviewed and checked with the shipper first for rates, requirements and feasibility.
																	</li>
																	<li>
																		Containers, such as styrofoam coolers or boxes, used for transport will be charged accordingly.
																	</li>
																	<li>
																		Total amount due with payment instructions will be provided to the customer once available.
																	</li>
																	<li>
																		Processing will start only after clearance of the full amount on the agreed payment facility.
																	</li>
																	<li>
																		Transaction is considered completed upon turnover of packages to the forwarder or it's representative.
																	</li>
																	<li>
																		Additional charges, tampering, pilferage, spoilage, and/or other incidents during shipping will be resolved by the customer directly with the forwarder.
																	</li>
																</ol>
																<Checkbox
																	label={<label htmlFor="agreeTerms">I hereby acknowledge that I have read, understand and agree to the <a href={'/terms'} target="_blank" rel="noopener noreferrer">Terms and Conditions </a> of NegosyoNow.com, with particular attention to the items above.</label>}
																	checked={agreeTerms}
																	onChange={()=>{
																		handleChangeTerms(!agreeTerms)
																	}}
																	id="agreeTerms"
																	name="agreeTerms"
																/>

															</div>
														)}
														{pickup!=="bus" && (
															<div className={styles.text}>
																By clicking the Proceed button below, I hereby acknowledge that I have read,
																understand and agree to the <a href={'/terms'} target="_blank" rel="noopener noreferrer">
																Terms and Conditions </a> of NegosyoNow.com
															</div>
														)}
														{this.state.errorCheckout &&
															<Message warning className={styles.msg} >
																<Message.Header name={"checkoutErrorMsg"}>{this.state.errorCheckout}</Message.Header>
															</Message>
														}
														{this.state.message &&
															<Message className={styles.msg} >
																<Message.Header name={"checkoutMsg"}>{this.state.message}</Message.Header>
															</Message>
														}
														{this.props.errorCheckout &&
															<Message negative className={styles.msg} >
																<Message.Header name={"checkoutErrorMsg"}>{this.props.errorCheckout}</Message.Header>
																Please try again
															</Message>
														}
														<Button
															content={"Back"}
															color={backColor}
															onClick={handleBackButton}
															disabled={loadingCheckout}
															className={styles.form}
															name={"checkoutBackButton"}
														/>
														{ checkoutButtonMsg ? (
															<Button
															 as="div"
															 labelPosition="right"
															>
																<Button
																	content={"Proceed"}
																	onClick={handleCheckout}
																	color={confirmationColor}
																	loading={loadingCheckout}
																	disabled={loadingCheckout || this.props.disableCheckout || this.props.openList.length >0}
																	className={styles.checkoutButton}
																	name={"checkoutProceedButton"}
																/>
																<Label
																	basic
																	content={checkoutButtonMsg}
																	pointing="left"
																	className={styles.label}
																/>
															</Button>
														):(
															<Button
																content={"Proceed"}
																onClick={handleCheckout}
																color={confirmationColor}
																loading={loadingCheckout}
																disabled={loadingCheckout || this.props.disableCheckout || this.props.openList.length >0}
																className={styles.mainButton}
																name={"checkoutProceedButton"}
															/>
														)}
*/







/* previously in contact information section but moved this to above the section/menu(below the subtotal/price)
{ pickup==="delivery" && (
															<Segment>
																<Header className={styles.header}>Shipping Address</Header>
																<ShowNewAddress address={shippingAddress}/>
															</Segment>
														)}
														{pickup==="pick up" && (
															<Segment>
																<Header className={styles.header}>
																	Pickup location
																</Header>
																<p className={styles.text}>

																	{pickupDetail}<br/>
																	Pick Up Method : {pickupMethodDetail}<br/>
																	{pickupAddress}
																	<a
																		href={`https://www.google.com/maps/search/NegosyoNow+${pickupDetail.replace(/\s/g,'+')}/@${pickupGps.latitude},${pickupGps.longitude},18z`}
																		target="_blank"
																		rel="noopener noreferrer"
																		title={`Link to google map location of ${pickupDetail}`}
																		className={styles.pickUpLink}
																	>
																		<Icon name="map marker alternate" />
																	</a>

																</p>
															</Segment>
														)}
														{pickup==="bus" && (
															<Segment>
																<Header className={styles.header}>Bus Shipping Details</Header>
																<span className={styles.text}>{`${busDetails} ${stationName}`}</span>
															</Segment>
														)}

*/


/*
<Menu.Item
									name="Pick Up Method"
									active={activeItem==='Pick Up Method'}
									onClick={handleItemClick}
									disabled={pickup!=="pick up"}
									className={styles.menuItem}
								/>
								<Menu.Item
									name="Bus Shipping Details"
									active={activeItem==='Bus Shipping Details'}
									onClick={handleItemClick}
									disabled={pickup!=="bus"}
									className={styles.menuItem}
								/>
								<Menu.Item
									name="Shipping Address"
									active={activeItem==='Shipping Address'}
									onClick={handleItemClick}
									disabled={pickup!=="delivery"}
									className={styles.menuItem}
								/>
*/

/*
options={
																<React.Fragment>
																	<Checkbox
																		label="Use a different address for Billing Address?"
																		checked={diffAddress}
																		name="diffAddress"
																		onClick={toggleValue}
																		className={styles.form}
																	/>
																	<br/>
																</React.Fragment>
															}
*/

/*
<Menu.Item
									name="Billing Address"
									active={activeItem==='Billing Address'}
									onClick={handleItemClick}
									disabled={pickup!=="delivery" || shippingAddress===undefined || diffAddress===false}
									className={styles.menuItem}
								/>
*/


/*
{ activeItem==="Billing Address" && (
									<React.Fragment>
										<Grid divided='vertically'>

											<Grid.Column width={16}>
												{ (loggedIn!==true)&&(
													<React.Fragment>
														<AddressForm
															handleSubmit={saveBillingAddress}
															values={billingAddress}
															buttonMessage={"Proceed"}
															backFunc={()=>{
																this.setState({activeItem:"Shipping Address"})
															}}
														/>

													</React.Fragment>
												)}
												{ (loggedIn===true ) && (
													<ShowAddress
														user={user}
														value={billingAddressId}
														handleChoose={saveBillingAddress}
														backFunc={()=>{
															this.setState({activeItem:"Shipping Address"})
														}}
													/>
												)}
											</Grid.Column>
										</Grid>
									</React.Fragment>
								)}
*/

/*
<React.Fragment>
										{ checkoutStage===0 && (
											<React.Fragment>

												{pickup==="pick up" && (
													<Segment>
														<Header className={styles.header}>
															Location Details
														</Header>
														<p className={styles.text}>
															Your chosen pickup location is: <br/>
															{pickupDetail}<br/>
															{pickupAddress}
															<a
																href={`https://www.google.com/maps/search/NegosyoNow+${pickupDetail.replace(/\s/g,'+')}/@${pickupGps.latitude},${pickupGps.longitude},18z`}
																target="_blank"
																rel="noopener noreferrer"
																title={`Link to google map location of ${pickupDetail}`}
																className={styles.pickUpLink}
															>
																<Icon name="map marker alternate" size="large" className={styles.icon}/>
															</a>

														</p>
													</Segment>
												)}
												<Segment>
													<Header className={styles.header}>
														Contact Person Details
														<Header.Subheader>
															{contactSubTitle}
														</Header.Subheader>
													</Header>
													<Form>
														<Form.Input
															label="Full Name*"
															placeholder="Your Full name"
															value={name}
															error={error.name}
															name="name"
															onChange={handleInputChange}
															className={styles.form}
														/>
														<Form.Input
															label="Mobile #*"
															placeholder="(e.g. +639XXXXXXXXX, 09XXXXXXXXX)"
															value={contact}
															error={error.contact}
															name="contact"
															onChange={handleInputChange}
															className={styles.form}
														/>
														<Form.Input
															label="Email"
															placeholder="Email"
															value={email}
															name="email"
															type="email"
															onChange={handleInputChange}
															className={styles.form}
														/>
													</Form>
												</Segment>
												{!loggedIn && (
													<React.Fragment>
														<br/>
														<Checkbox
															label="Want to Sign In/Up before checkout?"
															checked={signInUp}
															name="signInUp"
															onClick={toggleValue}
															className={styles.form}
														/>
														<br/>
														{signInUp && (
															<Segment>
																<Grid stackable columns={2} divided>
																	<Grid.Column >
																		{signUpStage===0 && (
																			<React.Fragment>
																				<SignUpForm
																					loginType="Password"
																					handleSubmit={(email, password)=>{
																						this.setState({
																							signUpContact:email,
																							signUpPassword:password,
																							signUpStage:1,
																							proper:true
																						})
																					}}
																				/>
																				<Button
																					color={activateAccountColor}
																					content="Activate Account"
																					onClick={toggleStage}
																					disabled={transition}
																					className={styles.form}
																				/>
																			</React.Fragment>
																		)}
																		{signUpStage===1 && (
																			<React.Fragment>
																				<AuthForm
																					email={signUpContact}
																					password={signUpPassword}
																					proper={proper}
																					backFunc={toggleStage}
																					backDisabled={transition}
																					handleSubmit={()=>{
																						Negosyo.getCurrentUserDetails()
																						.then((user)=>{
																							if(pickup==="delivery") {
																								if(diffAddress) {
																									this.setState({
																										user:user,
																										loggedIn:true,
																									})
																								}else {
																									this.setState({
																										user:user,
																										loggedIn:true,
																									})
																								}
																							}else {
																								this.setState({
																									user:user,
																									loggedIn:true,
																								})
																							}

																							this.props.changeLoggedIn()
																						}).catch((err)=>{
																							//console.log("error in getting user details: " + err)
																						})
																					}}
																				/>
																			</React.Fragment>
																		)}
																	</Grid.Column >
																	<Grid.Column >
																		<Container>
																			<SignInForm
																				handleSubmit={()=>{
																					Negosyo.getCurrentUserDetails()
																					.then((user)=>{
																						if(pickup==="delivery") {
																							if(diffAddress) {
																								this.setState({
																									user:user,
																									loggedIn:true,
																								})
																							}else {
																								this.setState({
																									user:user,
																									loggedIn:true,
																								})
																							}
																						}else {
																							this.setState({
																								user:user,
																								loggedIn:true,
																							})
																						}
																						this.props.changeLoggedIn()
																					}).catch((err)=>{
																						//console.log("error in getting user details: " + err)
																					})
																				}}
																			/>
																		</Container>
																	</Grid.Column>
																</Grid>
																<br/>
															</Segment>
														)}
													</React.Fragment>
												)}

												<br/>
												<Button
													content={"Back"}
													color={backColor}
													onClick={()=>{
														if(pickup==="pick up") {
															this.setState({activeItem:"Pickup or Delivery"})
														}else {
															this.setState({activeItem:diffAddress===true ? "Billing Address":"Shipping Address"})
														}
													}}
													disabled={loadingCheckout}
													className={styles.form}
												/>
												<Button
													content={"Proceed"}
													onClick={handleCheckout}
													color={confirmationColor}
													loading={loadingCheckout}
													disabled={loadingCheckout}
													className={styles.mainButton}
												/>

											</React.Fragment>
										)}

									</React.Fragment>


*/







/*   used for activating an account before
{!loggedIn && checkoutStage===1 && (
											<React.Fragment>
												{errorGuestCheck && (
													<Message negative className={styles.msg}>
														{errorGuestCheck}
													</Message>
												)}
												<Form >
													<Form.Input
														label="Authorization Code"
														placeholder="OTP Code"
														onChange={handleInputChange}
														error={error.guestAuthCode}
														value={guestAuthCode}
														name='guestAuthCode'
														action={{
															content: 'Resend Code',
															onClick: resendCode,
															type:"button"
														}}
														className={styles.form}
													/>
													<Button
														content="Back"
														color={backColor}
														onClick={()=>{
															this.setState({
																checkoutStage:0,
																guestAuthCode:""
															})
														}}
														type="button"
														disabled={loadingCheckout}
														className={styles.form}
													/>
													<Button
														color={confirmationColor}
														loading={loadingCheckout}
														disabled={loadingCheckout}
														onClick={confirmGuestCheckout}
														content="Proceed"
														className={styles.mainButton}
													/>
												</Form>
											</React.Fragment>
										)}


*/