import React, {useState, useEffect}from 'react'
import {
	Button,
	Form,
	Grid,
	//Icon,
	Loader,
	Message,
	Radio,
	Segment
} from 'semantic-ui-react'
import AddressForm from '../Forms/addressFormMod'
import * as styles from './styles/address.module.css'
import {
	//editAddressColor,
	//deleteAddressColor,
	backColor,
	confirmationColor
} from '../../globalVar'


const Negosyo = require('../../../lib/negosyo')
const Helper = require('../../helper')

/*handleEdit prop comment
 *used to clean up parent component(this) so it will only have addressForm
 *when pressing edit button on address cards
 */

/*handleBack prop comment
 *used for back button in showAddress when showing addressForm for edit
 *and for user successfully edits an address
 */
const ShowAddress = ({
	user,
	handleChoose,
	value,
	options,
	backFunc,
	backMessage,
	backDisabled,
	backStyle,
	transitionFunc, //a prop function for when changing of layout/looks
	loadingProp,
	errorProp
}) => {
	const [address, setAddress] = useState([])
	const [loadingAddress, setLoadingAddress] = useState(false)
	const [loadingAddAddress, setLoadingAddAddress] = useState(false)
	const [loadingPassAddress, setLoadingPassAddress] = useState(false)
	const [currentValue, setCurrentValue] = useState(value)
	//const [editMode, setEditMode] = useState(false)
	const [addMode, setAddMode] = useState(false)
	const [getAddressListError, setGetAddressListError] = useState(null)
	const [getAddressDetailsError, setGetAddressDetailsError] = useState(null)
	const [addressFormError, setAddressFormError] = useState(null)

	const fetchData = () => {
		setLoadingAddress(true)
		setGetAddressListError(null)//so if the retry succeeds it won't show the error
		Negosyo.listAddress(user.username).then((response)=>{
			if(response.status==="OK") {
				setAddress(response.data)
			}else if(response.status==="None") {
				setAddress([])
			}else {
				setGetAddressListError("Unable to retrieve Addresses")
			}
			if(transitionFunc) {
				transitionFunc()
			}
			setLoadingAddress(false)
		}).catch((err)=>{
			setGetAddressListError("Unable to retrieve Addresses")
			setLoadingAddress(false)
		})
	}

	useEffect(fetchData, [])




	/*

	const editAddress = (address) =>{
		Helper.handleFloor(address)
		const components = Helper.handleComponentCreation(address)
		Negosyo.updateAddress(user.username, uuid, components).then((data)=>{
			if(data.status==="OK") {
				handleBack()
				fetchData()
				setUuid(null)
				setEditMode(false)
				setAddressValues(null)
			}else {
				setAddressFormError("Error occurred. Please try again.")
				//setUuid(null)
			}
		}).catch((err)=>{
			setAddressFormError("Problem occurred. Please try again.")
		})
	}
	*/

	const handleChange = (e, {value}) => {
		setCurrentValue(value)
	}

	const mapAddressInfoSegment = (address) => {
		return (
			<Segment key={address.uuid}>
				<Form.Field >
					<Radio
						label={address.name}
						value={address.uuid}
						checked={currentValue===address.uuid}
						onChange={handleChange}
						className={`${styles.form} ${styles.deliveryMethod}`}
						name={"addressOption"}
					/>
				</Form.Field>

			</Segment>
		)
	}

	const saveAddress =({address}) => {
		setLoadingAddAddress(true)
		Helper.handleFloor(address)
		const components = Helper.handleComponentCreation(address)
		Negosyo.addAddress(user.username, components).then((data)=>{
			if(data.status==="OK") {

				fetchData()
				changeAddMode(false)
			}else {
				setAddressFormError("Unable to save new Address")
			}
			setLoadingAddAddress(false)
		}).catch((err)=>{
			setAddressFormError("Unable to save new Address")
			setLoadingAddAddress(false)
		})
	}

	const passAddress = () => {
		setLoadingPassAddress(true)
		Negosyo.getAddress(user.username, currentValue).then((response)=> {
			if(response.status==="OK") {

				let temp = []
				let locationCode;
				temp = temp.concat(response.data.components);
				temp = temp.concat(response.data.streetaddress.components);
				const addressFormValues = Helper.handleComponentToAddress(temp);
				Negosyo.getProvinceData()
				.then((provinces)=>{
					let temp = provinces.filter((province)=>{
						return province.name===addressFormValues.province
					})
					let matchProvince = temp[0];
					Negosyo.getCityData(matchProvince.code)
					.then((cities)=>{
						temp = cities.filter((cities)=>{
							return cities.name===addressFormValues.city
						})

						let matchCity = temp[0];
						locationCode = matchCity.code;
						if(addressFormValues.barangay!==undefined) {
							Negosyo.getBarangayData(matchCity.code)
							.then((barangays)=>{
								temp = barangays.filter((barangays)=>{
									return barangays.name===addressFormValues.barangay;
								})
								let matchBarangay = temp[0];
								locationCode = matchBarangay.code;
								handleChoose({address:addressFormValues, addressId:currentValue, locationCode});
								setLoadingPassAddress(false)
							}).catch((err)=>{
								setGetAddressDetailsError("Unable to retrieve address info")
								setLoadingPassAddress(false)
							})
						}else {
							handleChoose({address:addressFormValues, addressId:currentValue, locationCode});
							setLoadingPassAddress(false)
						}
					}).catch((err)=>{
						setGetAddressDetailsError("Unable to retrieve address info")
						setLoadingPassAddress(false)
					})
				}).catch((err)=>{
					setGetAddressDetailsError("Unable to retrieve address info")
					setLoadingPassAddress(false)
				})


			}else  {
				setLoadingPassAddress(false)
				setGetAddressDetailsError("Unable to retrieve address info")
			}

		}).catch((err)=>{
			setGetAddressDetailsError("Unable to retrieve address info")
			setLoadingPassAddress(false)
		})
	}
	const changeAddMode = (newAddMode) => {
		setAddMode(newAddMode)
		if(transitionFunc) {
			transitionFunc()
		}

	}
	return (
		<React.Fragment>
			{loadingAddress ? (
				<Loader active={loadingAddress} className={styles.loader} inline="centered"/>
			):(
				<React.Fragment>
					{ getAddressListError ? (
						<React.Fragment>
							<Message className={styles.msg} negative >
								<Message.Header name={"getAddressListErrorMsg"}>
									{getAddressListError}
								</Message.Header>
							</Message>
							<div className={`${styles.text} ${styles.centerText}`}>

								<Button
									icon="redo"
									size="big"
									className={styles.iconButton}
									onClick={fetchData}
								/>
								<br/><br/>
								Try Again
							</div>
						</React.Fragment>
					):(
						<React.Fragment>
							{ addMode ? (
								<Grid.Column width={16}>
									<React.Fragment>
										<AddressForm
											handleSubmit={saveAddress}
											buttonMessage={"Proceed"}
											loading={loadingAddAddress}
											backFunc={()=>{
												changeAddMode(false)
											}}
											error={addressFormError}
											backDisabled={loadingAddAddress}
										/>
									</React.Fragment>
								</Grid.Column>
							):(
								<Grid.Column width={16} >
									{address.map(mapAddressInfoSegment)}
									<Segment key={address.uuid}>
										<Form.Field >
											<Radio
												label={"Make a new Address"}
												value={"new address"}
												checked={currentValue==="new address"}
												onChange={handleChange}
												className={`${styles.form} ${styles.deliveryMethod}`}
												name={"addAddressOption"}
											/>
										</Form.Field>
									</Segment>
									{options}
									{ getAddressDetailsError && (
										<Message className={styles.msg} negative >
											<Message.Header name={"getAddressDetailsErrorMsg"}>
												{getAddressDetailsError}
											</Message.Header>
										</Message>
									)}
									{ errorProp && (
										<Message className={styles.msg} negative >
											<Message.Header name={"getAddressDetailsErrorMsg"}>
												{errorProp}
											</Message.Header>
										</Message>
									)}
									<br/>
									{backFunc && (
										<Button
											content={backMessage ? backMessage:"Back"}
											onClick={backFunc}
											disabled={backDisabled || loadingPassAddress || loadingProp}
											color={backColor}
											className={backStyle ? backStyle:styles.form}
											type={"button"}
											name={"checkoutBackButton"}
										/>
									)}
									<Button
										color={confirmationColor}
										content={"Proceed"}
										disabled={!currentValue || loadingPassAddress || loadingProp}
										onClick={currentValue==="new address" ?
											()=>{
												changeAddMode(true)
											}
											:passAddress
										}
										loading={loadingPassAddress || loadingProp}
										className={styles.mainButton}
										name={"checkoutProceedButton"}
									/>
								</Grid.Column>
							)}
						</React.Fragment>
					)}
				</React.Fragment>
			)}
		</React.Fragment>
	)
}
export default ShowAddress

