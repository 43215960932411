import React, { Component } from 'react'
import {
	Form,
	Radio,
	Button,
	Segment,
	Icon
} from 'semantic-ui-react'
import * as styles from '../styles/cartSummary.module.css'
import {confirmationColor} from '../../../globalVar'


export default class PickUpForm extends Component {
	state = {
		currentValue: this.props.value || undefined,
		address: this.props.address || undefined,
		pickupCode: this.props.pickupCode || undefined,
		pickupGps: this.props.pickupGps || undefined
	}

	handleChange = (e, {value, address, code, gps}) => {
		this.setState({
			currentValue:value,
			address:address,
			pickupCode:code,
			pickupGps:gps
		},()=>{
			//this call back anonymous function is for the auto proceed functionality for the method(1st) step in cart checkout
			const {name} = this.props

			const {
				currentValue,
				address,
				pickupCode,
				pickupGps
			} = this.state
			if( this.state.currentValue==="delivery" ) {
				this.props.handleDelivery()
			}else if(this.state.currentValue==="bus" && this.state.pickupCode) {
				this.props.handleBus(currentValue, pickupCode)
			}else if(this.state.currentValue && this.state.address) {
				this.props.handlePickUp(name, currentValue, address, pickupCode, pickupGps)
			}
		})
	}

	MakeRadioForm = (item) => {
		const {currentValue} =this.state
		const name = item.name.replace(" ", "").toLowerCase();
		return (
			<React.Fragment key={item.code}>
				<Segment>
					<Form.Field >
						<Radio
							label={`Pick up at ${item.name} (Cash upon pick up)`}
							value={item.name}
							checked={currentValue===item.name}
							//onChange={this.handleChange}
							className={`${styles.form} ${styles.deliveryMethod}`}
							code={item.code}
							address={item.address}
							gps={item.gps}
							disabled={item.available==="0"}
							onClick={this.handleChange}
							id={`${name}Option`}
							name={"methodOptions"}
						/>
					</Form.Field>
					<div>
						{item.address}
						<a
							href={`https://www.google.com/maps/search/NegosyoNow+${item.name.replace(/\s/g,'+')}/@${item.gps.latitude},${item.gps.longitude},18z`}
							target="_blank"
							rel="noopener noreferrer"
							title={`Link to google map location of ${item.name}`}
							className={styles.pickUpLink}
						>
							<Icon name="map marker alternate" size="large"/>
						</a>
					</div>
				</Segment>
			</React.Fragment>
		)
	}

	render() {
		const {items, name} = this.props

		const {
			currentValue,
			address,
			pickupCode,
			pickupGps
		} = this.state
		return(
			<Form>
				<Segment>
					<Form.Field >
						<Radio
							label={"Deliver to my Address (Cash upon delivery)"}
							value={"delivery"}
							checked={this.state.currentValue==="delivery"}
							//onChange={this.handleChange}
							className={`${styles.form} ${styles.deliveryMethod}`}
							onClick={this.handleChange}
							id={"deliveryOption"}
							name={"methodOptions"}
						/>
					</Form.Field>
					<div className={styles.divDeliveryInfo}>
						Metro Manila:
						<ul>
							<li>Php330.00 - Most areas (South Caloocan, Valenzuela, San Juan, Mandaluyong, Marikina, Manila, Pasay, Muntinlupa, Paranaque, Las Pinas, Taguig, Pasig, Makati, Some Parts of Quezon City, etc)</li>
							<li>Php550.00 - North Caloocan, Payatas, Novaliches, Fairview, Batasan, Some Parts of Quezon City</li>
							<span className={styles.italic}>** 50% discount on delivery fee when amount is Php 5,000.00 or higher</span>
						</ul>
						Outside of Metro Manila:
						<ul>
							<li>Starts at Php 495.00 - Subject for computation</li>
						</ul>
						<span className={styles.italic}>Delivery fees are still subject for computation</span>
					</div>
				</Segment>
				<Segment>
					<Form.Field >
						<Radio
							label={"Bus Shipping, Terminal Pickup (Bank deposit)"}
							value={"bus"}
							checked={this.state.currentValue==="bus"}
							code="NNBUSSHIPPING"
							//onChange={this.handleChange}
							className={`${styles.form} ${styles.deliveryMethod}`}
							onClick={this.handleChange}
							id={"busShippingOption"}
							name={"methodOptions"}
						/>
					</Form.Field>
					<div className={styles.divDeliveryInfo}>
						Php495.00 delivery fee or higher<br/>
						Kindly allow us to check for coverage<br/>
						<span className={styles.italic}>Delivery fees are still subject for computation</span>
					</div>
				</Segment>
				{items.map(this.MakeRadioForm)}
				<Button
					type="button"
					content="Proceed"
					color={confirmationColor}
					disabled={!this.state.currentValue}
					onClick={()=> {
						if( this.state.currentValue==="delivery" ) {
							this.props.handleDelivery()
						}else if(this.state.currentValue==="bus" && this.state.pickupCode) {
							this.props.handleBus(currentValue, pickupCode)
						}else if(this.state.currentValue && this.state.address) {
							this.props.handlePickUp(name, currentValue, address, pickupCode, pickupGps)
						}
					}}
					className={styles.form}
					id={"checkoutProceedButton"}
				/>
			</Form>
		)
	}
}
