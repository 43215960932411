import React, { Component } from 'react'
import {
	Button,
	Form,
} from 'semantic-ui-react'
import * as styles from '../styles/cartSummary.module.css'
import {confirmationColor, backColor} from '../../../globalVar'

/*
double check address and pickUpAddress in cartSummary if it can be deleted with no repurcussion
*/
export default class BusShippingForm extends Component {
	state = {
		busDetails:this.props.busDetails || '',
		stationName:this.props.stationName ||'',
		error: {
			busDetails:undefined,
			stationName:undefined
		}
	}

	handleInputChange = (e, { name, value }) => {
		this.setState({ [name]: value })
		if(	value==='' ){
			const message = " must not be blank"
			this.setState(prevState => ({
				error: {
					...prevState.error,
					[name] : message
				}
			}))
		} else {
			const message = null
			this.setState(prevState => ({
				error: {
					...prevState.error,
					[name] : message
				}
			}))
		}
	}

	validateInput = (states)=> {
		let check = true
		for( let key of Object.keys(states) ) {
			if( states[key] === '' ) {
				check = false
				this.setState(prevState => ({
					error: {
						...prevState.error,
						[key] : "must not be blank"
					}
				}))
			}
		}
		return check
	}

	handleSubmit = () =>{
		const {
			busDetails,
			stationName
		} = this.state
		const check = this.validateInput({busDetails, stationName})
		if(check) {
			this.props.handleSubmit(busDetails, stationName)
		}
	}

	cleanUp = (states) => {
		const tempState = {}
		for( let key of Object.keys(states) ) {
			if( states[key] !== '' ){
				tempState[key] = this.state[key].trim();
				tempState[key] = tempState[key].replace(/ +/g, " ");
			}
		}
		this.setState(
			tempState
		, this.handleSubmit)

	}

	render() {
		const {
			busDetails,
			stationName,
			error
		} = this.state

		const {
			handleInputChange,
			cleanUp
		} = this
		return(
			<Form>
				<Form.Input
					label="Bus Liner"
					placeholder="(e.g. Victory Liner, Eagle Star, etc)"
					value={busDetails}
					name='busDetails'
					error={error.busDetails}
					onChange={handleInputChange}
					className={styles.form}
				/>
				<Form.Input
					label="Terminal/Station Name"
					placeholder="(e.g. Cubao, Olongapo, etc)"
					value={stationName}
					name='stationName'
					error={error.stationName}
					onChange={handleInputChange}
					className={styles.form}
				/>
				<div className={styles.text}>
					Kindly allow us to check for coverage. The transaction will be considered completed once the
					goods have been turned over to the courier
				</div>
				{this.props.backFunc && (
					<Button
						color={backColor}
						content={this.props.backMessage ? this.props.backMessage:"Back"}
						onClick={this.props.backFunc}
						className={this.props.backStyle ? this.props.backStyle:styles.form}
						disabled={this.props.backDisabled}
						type="button"
						name={"checkoutBackButton"}
					/>
				)}
				<Button
					content="Proceed"
					color={confirmationColor}
					onClick={()=> {
						cleanUp({busDetails, stationName})
					}}
					className={styles.mainButton}
					name={"checkoutProceedButton"}
				/>
			</Form>
		)
	}
}
