import React, { Component } from 'react'
import {
	Form,
	Radio,
	Button,
	Segment,
} from 'semantic-ui-react'
import * as styles from '../styles/cartSummary.module.css'
import {confirmationColor, backColor} from '../../../globalVar'

/*
double check address and pickUpAddress in cartSummary if it can be deleted with no repurcussion
*/
export default class PickUpMethodForm extends Component {
	state = {
		currentValue: this.props.value || undefined,
		courierDetail: this.props.courierDetail || '',
		error: {
			courierDetail:undefined
		}
	}

	handleChange = (e, {value}) => {
		if(value==="Personal Pick Up") {
			this.setState({currentValue:value, courierDetail:"Personal Pick Up", error:{courierDetail:undefined}})
		}else {
			this.setState({currentValue:value, courierDetail:'', error:{courierDetail:undefined}})
		}
	}

	handleInputChange = (e, { name, value }) => {
		this.setState({ [name]: value })
		if(	value==='' ){
			const message = " must not be blank"
			this.setState(prevState => ({
				error: {
					...prevState.error,
					[name] : message
				}
			}))
		} else {
			const message = null
			this.setState(prevState => ({
				error: {
					...prevState.error,
					[name] : message
				}
			}))
		}
	}

	validateInput = (states)=> {
		let check = true
		for( let key of Object.keys(states) ) {
			if( states[key] === '' ) {
				check = false
				this.setState(prevState => ({
					error: {
						...prevState.error,
						[key] : "must not be blank"
					}
				}))
			}
		}
		return check
	}

	handleSubmit = () =>{
		const {
			courierDetail,
			currentValue
		} = this.state
		const check = this.validateInput({courierDetail})
		if(check) {
			this.props.handleSubmit(currentValue, courierDetail)
		}
	}

	cleanUp = (states) => {
		const tempState = {}
		for( let key of Object.keys(states) ) {
			if( states[key] !== '' ){
				tempState[key] = this.state[key].trim();
				tempState[key] = tempState[key].replace(/ +/g, " ");
			}
		}
		this.setState(
			tempState
		, this.handleSubmit)

	}

	render() {
		const {
			currentValue,
			courierDetail,
			error
		} = this.state

		const {
			handleInputChange,
			cleanUp
		} = this
		return(
			<Form>
				<Segment>
					<Form.Field >
						<Radio
							label={"Personal Pick Up"}
							value={"Personal Pick Up"}
							checked={this.state.currentValue==="Personal Pick Up"}
							onChange={this.handleChange}
							className={`${styles.form} ${styles.deliveryMethod}`}
							id={"personalOption"}
							name={"pickupMethodOptions"}
						/>
					</Form.Field>
				</Segment>
				<Segment>
					<Form.Field >
						<Radio
							label={"Customer-Booked Pick Up (e.g. Grab, Lalamove etc)"}
							value={"Courier"}
							checked={this.state.currentValue==="Courier"}
							onChange={this.handleChange}
							className={`${styles.form} ${styles.deliveryMethod}`}
							id={"courierOption"}
							name={"pickupMethodOptions"}
						/>
					</Form.Field>
				</Segment>
				{currentValue==="Courier" && (
					<React.Fragment>
						<Form.Input
							label="Courier Service"
							placeholder="(e.g. Grab, Lalamove, etc)"
							value={courierDetail}
							name='courierDetail'
							error={error.courierDetail}
							onChange={handleInputChange}
							className={styles.form}
							id={"pickupMethodCourier"}
						/>
						<p className={styles.text}>
							The customer will be solely responsible for booking, coordination and payment.
							The transaction will be considered completed once goods have been picked up by the service.
						</p>
					</React.Fragment>
				)}
				{this.props.backFunc && (
					<Button
						color={backColor}
						content={this.props.backMessage ? this.props.backMessage:"Back"}
						onClick={this.props.backFunc}
						className={this.props.backStyle ? this.props.backStyle:styles.form}
						disabled={this.props.backDisabled}
						type="button"
						name={"checkoutBackButton"}
					/>
				)}
				<Button
					content="Proceed"
					color={confirmationColor}
					disabled={!this.state.currentValue || courierDetail===""}
					onClick={()=> {
						cleanUp({courierDetail})
					}}
					className={styles.mainButton}
					name={"checkoutProceedButton"}
				/>
			</Form>
		)
	}
}
